import { FaMountain, FaRegUser } from "react-icons/fa";
import { Main } from "../components/Main/Main";
import { MdAbc } from "react-icons/md";
import { BsChevronDown, BsPlus } from "react-icons/bs";
import { cn } from "../lib/utils";
import { BASE_URL, FORM, URL } from "../config/api.config";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import MetricsFormsCard, { Form } from "../components/Cards/MetricsFormsCard";
import { IoIosClose, IoMdExit } from "react-icons/io";
import { HiOutlinePlus } from "react-icons/hi";
import { useModalImage } from "../hooks/use-open-modal-image";
import ModalUploadImage from "../components/Modals/ModalUploadImage";
import { useModalOptions } from "../hooks/use-open-modal-options";
import ModalEditOptions from "../components/Modals/ModalEditOptions";
import { useNavigate } from "react-router-dom";
import { formFinalTypes, formsTypes } from "../data/content";
import PreviewForms from "../components/Forms/PreviewForms";
import MetricsFinalFormCard from "../components/Cards/MetrisFinalFormsCard";
import { VscLayers } from "react-icons/vsc";
import { useTokenStore } from "../hooks/useTokenStore";

const Metrics = () => {
  const [selectForm, setSelectForm] = useState<any>();
  const modalOptions = useModalOptions();
  const navigate = useNavigate();
  const [showDropdownFinal, setShowDropdownFinal] = useState(false);
  const ref = useRef<any>(null);
  const token = useTokenStore((s) => s.token);

  const modalImage = useModalImage();
  const getForm = async () => {
    // const data = await fetch(`${BASE_URL}${FORM}?${selectForm.id}`, {
    const data = await fetch(
      `${BASE_URL}${FORM}${
        selectForm.id === "q6" ? "?q1=1&q2=3&q3=4" : `?${selectForm.id}`
      }`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => {
        console.log(err);
      });

    return data?.result;
  };

  const { data: formData } = useQuery({
    queryKey: ["form", selectForm?.id],
    queryFn: getForm,
  });

  useEffect(() => {
    // Function to handle the click event
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdownFinal(false);
      }
    }

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <Main>
      <div className="flex w-full">
        <div className=" bg-white h-full z-10 w-72 flex flex-col items-center p-4">
          <div className="bg-[#f8f8f8] m-1 p-1">
            {formsTypes.map((form: Form) => (
              <MetricsFormsCard
                key={form.id}
                setSelectForm={setSelectForm}
                selectForm={selectForm}
                form={form}
              />
            ))}
          </div>
          <div className="bg-[#f8f8f8]  m-1 p-1  mt-4">
            <div className="p-2 w-60 ">
              <div className="flex items-center justify-between relative">
                <h1 className="text-sm font-medium">Finals</h1>
                <div
                  onClick={() => setShowDropdownFinal(true)}
                  className="bg-white rounded border h-10 w-10 flex items-center justify-center cursor-pointer hover:bg-gray-200 transition"
                >
                  <BsPlus className="h-6 w-6" />
                </div>
                <div
                  ref={ref}
                  className={cn(
                    " w-52   bg-white absolute top-0 transition rounded-xl p-1 -right-52 z-20 shadow border-4 border-gray-200 ",
                    showDropdownFinal ? "block" : "hidden"
                  )}
                >
                  <h1 className="text-sm font-medium px-3 py-2">
                    Choose a final type
                  </h1>
                  <div className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-gray-200 transition cursor-pointer">
                    <VscLayers className="h-5 w-5" />
                    <p className="text-sm">Final Screen</p>
                  </div>
                  <div className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-gray-200 transition cursor-pointer">
                    <IoMdExit className="h-5 w-5" />
                    <p className="text-sm">Redirection to URL </p>
                  </div>
                </div>
              </div>
            </div>
            {formFinalTypes.map((form: Form) => (
              <MetricsFinalFormCard
                key={form.id}
                form={form}
                setSelectForm={setSelectForm}
                selectForm={selectForm}
              />
            ))}
          </div>
        </div>
        <PreviewForms
          selectForm={selectForm}
          setSelectForm={setSelectForm}
          formData={formData}
        />
        <div className="bg-gray-50  h-full w-80">
          <div className="flex items-center  p-4 ">
            <div className="w-20 flex items-center justify-center   pb-1 hover:bg-gray-200 cursor-pointer">
              <h1 className="font-medium text-sm">Content</h1>
            </div>
            <div
              onClick={() => navigate("/create")}
              className="w-20 flex items-center justify-center  pb-1 hover:bg-gray-200 cursor-pointer"
            >
              <h1 className="text-sm">Logic</h1>
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="border-t border-b py-4 mx-4">
              <select className="hover:bg-gray-200 transition w-full border p-1">
                <option>
                  <div className="flex items-center space-x-2">
                    {selectForm?.type === "contact_information" && (
                      <>
                        <FaRegUser className="h-4 w-4" />
                      </>
                    )}
                    {selectForm?.type === "multiple_selection" && (
                      <>
                        <MdAbc className="h-4 w-4" />
                      </>
                    )}
                    {selectForm?.type === "selector" && (
                      <>
                        <BsChevronDown className="h-4 w-4" />
                      </>
                    )}
                    {selectForm?.type === "selector_with_images" && (
                      <>
                        <FaMountain className="h-4 w-4 " />
                      </>
                    )}
                    <p className="text-sm">Image Selection</p>
                  </div>
                </option>
              </select>
            </div>
            <div className="px-4 mt-4">
              <h1 className="text-sm font-medium">Question</h1>
              <p className="py-1 text-sm  text-gray-500">
                {formData?.questions?.[0].text}
              </p>
            </div>
            <div className="px-4 mt-4">
              <h1 className="text-sm font-medium">Description (Optional)</h1>
              <p className="py-1 text-sm text-gray-500">
                {formData?.questions?.[0].optional_detail}
              </p>
            </div>
            <h1 className="text-sm font-medium  px-4 mb-2 mt-4">Options</h1>
            {formData?.options?.length > 0 &&
              formData?.options?.map(
                (option: {
                  id: string;
                  option_text: string;
                  img_path?: string;
                }) => (
                  <div
                    key={option.id}
                    className="px-4 py-1 bg-gray-100 cursor-pointer transition-all rounded mx-2 my-1 group flex items-center justify-between"
                  >
                    <h1 className="text-sm line-clamp-1">
                      {option.option_text}
                    </h1>
                    <div className="hover:bg-gray-200 group-hover:block hidden rounded-full">
                      <IoIosClose className={cn("  h-5 w-5  ")} />
                    </div>
                  </div>
                )
              )}
            <div className="bg-gray-100 hover:bg-gray-200 transition cursor-pointer py-2 rounded mx-2 my-1 px-4 flex items-center space-x-2">
              <HiOutlinePlus className="h-4 w-4" />
              <h1 className="text-sm">Add Option</h1>
            </div>
            {selectForm?.type === "selector_with_images" && (
              <div className="border-t border-b py-4 mx-4 mt-4 flex items-center justify-between">
                <h1 className="text-sm font-medium">Image or video</h1>

                <div
                  onClick={() => modalImage.onOpen("modalImage")}
                  className="h-7 w-7 bg-white border cursor-pointer hover:bg-gray-100 transition flex items-center justify-center rounded-lg"
                >
                  <HiOutlinePlus className="h-4 w-4" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {modalImage.isOpen && <ModalUploadImage />}
      {modalOptions.isOpen && <ModalEditOptions />}
    </Main>
  );
};

export default Metrics;
