import React, { useState } from "react";
import { useModalBulkCSV } from "src/hooks/use-open-modal-bulk-csv";
import ModalGeneral from "./ModalGeneral";
import { FileInput, Label } from "flowbite-react";
import Papa from "papaparse";
import { IoIosClose } from "react-icons/io";
import { cn } from "src/lib/utils";
import Modal from "react-modal";
import { Button } from "../ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { BASE_URL } from "src/config/api.config";
import { useTokenStore } from "src/hooks/useTokenStore";
import { FaDownload, FaLock } from "react-icons/fa6";
import ProductCSV from "src/assets/csv/productCSV_sample.csv";

const customStyles: any = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    zIndex: 200,
  },
  content: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "100%",
    left: "50%",
    right: "50%",
    overflow: "auto",
    border: "none",
    background: "#fff",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    backgroundColor: "transparent",
  },
};

Modal.setAppElement("#root");

const ModalUploadCSV = () => {
  const uploadBulk = useModalBulkCSV();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const token = useTokenStore((s) => s.token);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      if (file.type === "text/csv") {
        const reader = new FileReader();
        reader.onload = function (e) {
          const text = e.target?.result as string;
          Papa.parse(text, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              setCsvData(results.data);
              setSelectedFile(file);
              setError(null);
            },
            error: (err) => {
              setError("Error parsing CSV file");
              console.error(err);
            },
          });
        };
        reader.readAsText(file);
      } else {
        setError("Please upload a valid CSV file.");
        setSelectedFile(null);
        toast.error("Please upload a valid CSV file.");
      }
    }
  };

  const uploadFile = async () => {
    if (!selectedFile) {
      toast.error("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("csv_file", selectedFile);

    try {
      const response = await fetch(`${BASE_URL}canopy.trays.bulk.upload`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });

      const data = await response.json(); // Parse the response as JSON

      if (data.status === "ok") {
        // Handle the case when the response contains rowsAdded or errors
        if (data.result.rowsAdded) {
          // Case when rows were successfully added (no errors)
          toast.success(
            `File uploaded successfully! Rows added: ${data.result.rowsAdded}`
          );
          uploadBulk.onClose(); // Close the modal on success
        } else if (data.result.errors && data.result.errors.length > 0) {
          // Case when there are errors in the response
          data.result.errors.forEach((error) => {
            toast.error(`Error: ${error}`);
          });
        }
      } else {
        toast.error("Failed to upload the file.");
      }
    } catch (error) {
      toast.error("An error occurred while uploading the file.");
      console.error(error);
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        isOpen={uploadBulk.isOpen}
        onRequestClose={() => uploadBulk.onClose()}
        style={customStyles}
      >
        <div
          className={cn(
            "fixed z-10 min-h-[400px] transition duration-150 w-[90%] laptop:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]",
            uploadBulk.isOpen ? "animate-slide-in" : "animate-slide-out"
          )}
        >
          <div className="flex items-center justify-between border-b pb-2 pr-2 bg-black text-white">
            <h1 className="text-sm font-medium px-4 py-2 capitalize">
              {"Upload CSV File"} | {uploadBulk.option}
            </h1>
            <div
              onClick={() => uploadBulk.onClose()}
              className="h-7 w-7 flex items-center cursor-pointer justify-center rounded-lg  hover:bg-gray-700 transition"
            >
              <IoIosClose className="h-5 w-5" />
            </div>
          </div>
          <div className="p-4">
            <div className="border-b mb-4 flex items-center justify-between">
              <h1 className="text-sm   pb-2">Upload your file</h1>
              <div className="flex items-center space-x-2 py-2">
                <FaDownload className="h-3 w-3 text-[#333]" />

                <a className="underline text-sm" href={ProductCSV} download>
                  Download csv example
                </a>
              </div>
            </div>
            {!selectedFile && (
              <>
                <div className="flex w-full items-center justify-center">
                  <Label
                    htmlFor="dropzone-file"
                    className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                      <svg
                        className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click to upload your CSV File
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        .csv, .xlsx o .txt
                      </p>
                    </div>
                    <FileInput
                      id="dropzone-file"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </Label>
                </div>
                <div className="my-4">
                  <div className="flex items-start space-x-2">
                    <FaLock className="h-4 w-4 text-slate-400" />
                    <span className="text-sm text-slate-400">
                      We do not sell or rent to third parties, nor do we use
                      your database for commercial purposes.{" "}
                      <span className="text-[#333] underline">
                        Lea nuestra Política de privacidad
                      </span>
                    </span>
                  </div>
                </div>
              </>
            )}

            {/* Display CSV data preview */}
            <div className="mt-4 ">
              {error && <p className="text-red-500">{error}</p>}
              <div className="w-full overflow-x-auto">
                {csvData.length > 0 && (
                  <>
                    {selectedFile && (
                      <div className="border-green-400  flex items-start justify-between bg-gray-50 border rounded p-4 py-5">
                        <div className="flex flex-col items-start">
                          <p className="mb-2 text-[#333]">
                            The file is uploaded!
                          </p>
                          <p className="text-xs text-gray-500">
                            {selectedFile.name}
                          </p>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setSelectedFile(null);
                            setCsvData([]);
                          }}
                        >
                          <p className="font-medium underline">Cancel</p>
                        </div>
                      </div>
                    )}
                    <h1 className="font-medium mb-2">Preview your csv file</h1>
                    <table className="min-w-full table-auto border-collapse border border-gray-300">
                      <thead className="bg-white">
                        <tr>
                          {Object.keys(csvData[0]).map((header, index) => (
                            <th
                              key={index}
                              className="border border-gray-300 px-4 py-2 text-sm uppercase text-gray-500 font-normal"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {csvData.slice(0, 3).map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {Object.values(row).map((value, colIndex) => (
                              <td
                                key={colIndex}
                                className="border border-gray-300 px-4 py-2 text-sm "
                              >
                                <p className="line-clamp-1">{value}</p>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
              {csvData.length > 0 && (
                <div className="flex flex-col items-center justify-center my-4">
                  <Button onClick={() => uploadFile()}>Upload CSV File</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalUploadCSV;
