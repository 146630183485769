import axios from "axios";
import React, { useState, useEffect } from "react";
import { Main } from "../components/Main/Main";
import { BASE_URL, QUOTES } from "../config/api.config";
import { useTokenStore } from "../hooks/useTokenStore";
import {
  format,
  isThisMonth,
  parseISO,
  subMonths,
  isSameMonth,
} from "date-fns";
import { IoArrowUpCircle, IoArrowDownCircle } from "react-icons/io5";
import { LineChart } from "@mui/x-charts/LineChart";
import { Quote } from "../types";
import dayjs from "dayjs";
import { TableStats } from "src/components/Statistics/TableStats";

const Statistics = () => {

  return (
    <Main>
      <div className="flex flex-col laptop:flex-row justify-center w-full">
        <div className="p-4 laptop:pb-20 gap-10">
          <h1 className="text-xl font-semibold py-4">Stats last 6 months</h1>

          <TableStats />
        </div>
      </div>
    </Main>
  );
};

export default Statistics;
