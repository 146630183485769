import React, { useEffect, useState } from "react";
import { useModalOptions } from "../../hooks/use-open-modal-options";
import ModalGeneral from "./ModalGeneral";

const ModalEditOptions = () => {
  const modalOptions = useModalOptions();
  const [valueTextarea, setValueTextarea] = useState("");

  useEffect(() => {
    const optionsText = modalOptions.options?.map(
      (option) => option.option_text
    );
    setValueTextarea(optionsText?.join(",").replaceAll(",", "\n"));
  }, []);

  return (
    <ModalGeneral
      title="Edit options"
      showModal={modalOptions.isOpen}
      closeModal={modalOptions.onClose}
    >
      <div className="p-4">
        <div className=" mb-4 flex items-center justify-between">
          <p className="text-sm pb-2">
            Write or delete your following options. Each option must go in a
            separate line
          </p>
        </div>
        <div className="flex w-full items-center justify-center">
          <textarea
            defaultValue={valueTextarea}
            className="border h-52 w-full p-2 text-sm"
          ></textarea>
        </div>
      </div>
    </ModalGeneral>
  );
};

export default ModalEditOptions;
