import React, { ReactNode } from "react";
import { IoIosClose } from "react-icons/io";
import Modal from "react-modal";
import { cn } from "../../lib/utils";

const customStyles: any = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    zIndex: 200,
  },
  content: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "100%",
    left: "50%",
    right: "50%",
    overflow: "auto",
    border: "none",
    background: "#fff",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    backgroundColor: "transparent",
  },
};
Modal.setAppElement("#root");

interface ModalGeneralProps {
  showModal: boolean;
  closeModal: () => void;
  children: ReactNode;
  title: string;
}
const ModalGeneral = ({
  children,
  closeModal,
  showModal,
  title,
}: ModalGeneralProps) => {
  return (
    <Modal isOpen={showModal} onRequestClose={closeModal} style={customStyles}>
      <div
        className={cn(
          "fixed z-10 h-[400px] transition duration-150 w-[90%] laptop:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]",
          showModal ? "animate-slide-in" : "animate-slide-out"
        )}
      >
        <div className="flex items-center justify-between border-b pb-2 pr-2 bg-black text-white">
          <h1 className="text-sm font-medium px-4 py-2">{title}</h1>
          <div
            onClick={() => closeModal()}
            className="h-7 w-7 flex items-center cursor-pointer justify-center rounded-lg  hover:bg-gray-700 transition"
          >
            <IoIosClose className="h-5 w-5" />
          </div>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalGeneral;
