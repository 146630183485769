import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { debounce } from "../../lib/debounce";
import { Input } from "../ui/Input";
import { BASE_URL, FORM } from "../../config/api.config";
import { ShowItem, ShowModal } from "./ManagementGeneral";
import { OptionsTypes } from "../../types";
import { Pencil, Trash } from "lucide-react";
import ModalAddManagement from "./ModalAddManagement";
import { Button } from "../ui/Button";
import { cn } from "../../lib/utils";
import { useTokenStore } from "../../hooks/useTokenStore";
import { CiImport } from "react-icons/ci";
import { useModalBulkCSV } from "src/hooks/use-open-modal-bulk-csv";

interface CardManagementYearProps {
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  showItem: ShowItem;

  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  selectedYear: OptionsTypes;
  setSelectedYear: Dispatch<SetStateAction<OptionsTypes>>;
}

const CardManagementYear: React.FC<CardManagementYearProps> = ({
  selectedYear,
  setSelectedYear,
  setShowItem,
  setShowModal,
  showItem,
}) => {
  const [inputYear, setInputYear] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [yearOptions, setYearOptions] = useState<OptionsTypes[]>([]);
  const token = useTokenStore((s) => s.token);
  const modalUploadBulk = useModalBulkCSV();

  const handleYearSearch = (e: string) => {
    setInputYear(e);
  };

  const getForm = async () => {
    const data = await fetch(`${BASE_URL}${FORM}?q1=1&q2=6`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setYearOptions(data.result.options);
      })
      .catch((err) => {
        console.log(err);
      });

    return data;
  };

  useEffect(() => {
    getForm();
  }, []);

  const optimisedSearch = useCallback(debounce(handleYearSearch), []);

  const createNewYear = () => {
    console.log("create new year");
  };

  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between mb-4 cursor-pointer">
        <div className="flex items-center">
          <p className="font-bold text-lg">Years</p>
          <Input
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11 `}
            placeholder="Search year"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
          <div className="flex items-center space-x-2">
            <Button onClick={() => setShowAddModal(true)} type="button">
              + Add a year
            </Button>
            <Button
              variant={"outline"}
              onClick={() => modalUploadBulk.onOpen("modalBulkCsv", "year")}
              type="button"
            >
              <CiImport />
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white border flex justify-between rounded">
        <div className="w-full p-4 text-sm h-96  overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full overflow-auto">
            {yearOptions.map(
              (
                option: {
                  name: string;
                  id: string;
                  option_text: string;
                  img_path: string | null;
                },
                i: number
              ) => (
                <div
                  onMouseEnter={() => {
                    setShowItem((prev) => ({
                      ...prev,
                      trash: i,
                      edit: i,
                      where: "year",
                    }));
                  }}
                  onMouseLeave={() => {
                    setShowItem((prev) => ({
                      ...prev,
                      trash: -1,
                      edit: -1,
                      where: "",
                    }));
                  }}
                  key={option.id}
                  className={cn(
                    " cursor-pointer py-2 border-b px-2 flex justify-between items-center",
                    selectedYear.id === option.id && "bg-gray-100"
                  )}
                  onClick={() => {
                    setSelectedYear(option);
                  }}
                >
                  <p>{option.option_text}</p>
                  <div className="flex items-center gap-3">
                    {showItem.edit === i && showItem.where === "year" && (
                      <div className="h-[24px] w-[24px] hover:bg-slate-200 flex items-center justify-center rounded-full">
                        <div
                          onClick={() =>
                            setShowModal((prev) => ({
                              ...prev,
                              edit: true,
                              section: "year",
                            }))
                          }
                        >
                          <Pencil className="h-5 w-5 " />
                        </div>
                      </div>
                    )}
                    {showItem.trash === i && showItem.where === "year" && (
                      <div className="h-[24px] w-[24px] hover:bg-slate-200 flex items-center justify-center rounded-full">
                        <div
                          onClick={() =>
                            setShowModal((prev) => ({
                              ...prev,
                              delete: true,
                              edit: false,
                              section: "year",
                            }))
                          }
                          className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                        >
                          <Trash className="h-5 w-5 " />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddManagement
          showModal={showAddModal}
          handleAction={createNewYear}
          section="year"
          setSelectedVehicle={setSelectedYear}
          setShowModal={setShowAddModal}
          type="add"
        />
      )}
    </div>
  );
};

export default CardManagementYear;
