import React from "react";
import { IoMdLogOut } from "react-icons/io";
import { Main } from "../components/Main/Main";
import { FaEdit, FaUser } from "react-icons/fa";
import { useTokenStore } from "../hooks/useTokenStore";
import { useUserInfo } from "../hooks/useUserInfo";

const Settings = () => {
  const setToken = useTokenStore((s) => s.setToken);
  const userInfo = useUserInfo((s) => s.userInfo);

  const handleLogout = () => {
    setToken("");
  };
  return (
    <Main>
      <div className="flex flex-col w-full p-4">
        <h1 className="text-xl font-semibold">Settings</h1>
        <h3 className="text-sm mt-2 text-gray-500">
          Put face to your name, changes your access data and your preferences
        </h3>

        <div className=" w-full flex justify-center ">
          <div className="w-[1000px] bg-white rounded-2xl mt-20 p-10 shadow-xl h-[700px]">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-sm font-medium">Merequetenge</h1>
                  <h3 className="text-gray-500 text-sm ">
                    Member since 22 July 2024
                  </h3>
                </div>
                <div className="bg-gray-200 px-2 py-3 flex items-center space-x-2 rounded-lg">
                  <FaUser className="h-5 w-5" />
                  <p className="text-sm font-medium">Admin</p>
                </div>
              </div>
              <div className="mt-4 flex items-center space-x-3">
                <button className="bg-gray-200 text-sm py-2 px-2 rounded-lg">
                  Change name
                </button>
                <button className="bg-gray-200 text-sm py-2 px-2 rounded-lg">
                  Change picture
                </button>
              </div>
              <div className="mt-4 border-t pt-4">
                <h1 className=" text-sm font-medium">Account info</h1>
                <div className=" flex gap-10 items-center">
                  <div className="flex flex-col items-start">
                    <p className="text-gray-500 text-sm">Your email is:</p>
                    <p className="text-black text-sm ">{userInfo}</p>
                  </div>
                  <button className="w-14 h-14 rounded-full flex justify-center shadow-sm  items-center">
                    <FaEdit color="orange" size={30} />
                  </button>
                </div>
                <div className="mt-4 flex items-center space-x-3">
                  <button className="bg-gray-200 text-sm py-2 px-2 rounded-lg">
                    Change email
                  </button>
                  <button className="bg-gray-200 text-sm py-2 px-2 rounded-lg">
                    Change password
                  </button>
                </div>
              </div>
              <div className="mt-4 border-t pt-4">
                <h1 className="text-sm font-medium">Language</h1>
                <h3 className="text-gray-500 text-sm">
                  The account language is:
                </h3>
                <select className="w-32 border rounded mt-4 px-3 py-1 cursor-pointer">
                  <option>English</option>
                </select>
              </div>
            </div>
            <button
              onClick={() => handleLogout()}
              className="rounded-lg mt-20 bg-gray-700 hover:bg-black w-40 h-10 text-white flex items-center gap-4 p-4"
            >
              <IoMdLogOut size={30} />
              Log out
            </button>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Settings;
