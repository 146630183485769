import React from "react";
import { Main } from "../components/Main/Main";
import { TableContainer, Paper, Typography, Button,Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";



export const Prices = () => {
  return (
    <Main>
      <div className="flex flex-col w-full p-20">
      <div className="overflow-x-auto ">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex justify-between items-center py-4">
            <Button
              variant="contained"
              color="error"
            >
              Previous
            </Button>

            <div className="flex items-center text-black">
              <Typography variant="body1" className="mx-2">
                Page  of 
              </Typography>
            </div>

            <Button variant="contained" color="error">
              Next
            </Button>
          </div>
        </div>
      </div>
    </Main>
  );
};
