import React, { Dispatch, SetStateAction, useState } from "react";
import { Input } from "../ui/Input";
import { OptionsTypes } from "../../types";
import { Button, buttonVariants } from "../ui/Button";
import { cn } from "../../lib/utils";

interface ModalAddManagementProps {
  section: string;
  type: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setSelectedVehicle: Dispatch<SetStateAction<OptionsTypes>>;
  handleAction: (args1: string) => void;
  showModal: boolean;
}

const ModalAddManagement: React.FC<ModalAddManagementProps> = ({
  section,
  type,
  handleAction,
  setSelectedVehicle,
  setShowModal,
  showModal,
}) => {
  const [vehicleName, setVehicleName] = useState("");
  const [yearName, setYearName] = useState("");
  const [quotedOptionName, setQuotedOptionName] = useState("");
  const [branchName, setBranchName] = useState("");

  const handleContinue = () => {
    if (section === "vehicle") {
      handleAction(vehicleName);
    }
    if (section === "year") {
      handleAction(yearName);
    }
    if (section === "branch") {
      handleAction(branchName);
    }
    if (section === "quoted-options") {
      handleAction(quotedOptionName);
    }
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/50 text-black z-50">
      <div
        className={cn(
          "fixed z-[50] w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white",
          showModal ? "animate-slide-in" : "animate-slide-out"
        )}
      >
        <div className="text-xl font-bold border-b px-8 py-6">
          {section === "vehicle" && <>{type === "add" && "Add Vehicle"}</>}
          {section === "year" && <>{type === "add" && "Add Year"}</>}
          {section === "branch" && <>{type === "add" && "Add Branch"}</>}
          {section === "quoted-options" && (
            <>{type === "add" && "Add Quoted Option"}</>
          )}
        </div>
        <div className="mx-8 my-6">
          {section === "vehicle" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>Vehicle:</p>
                <Input
                  autoFocus
                  placeholder="Enter a vehicle"
                  value={vehicleName}
                  onChange={(e) => setVehicleName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "year" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>Year:</p>
                <Input
                  autoFocus
                  placeholder="Enter a year"
                  value={yearName}
                  onChange={(e) => setYearName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "quoted-options" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>Quoted Option:</p>
                <Input
                  autoFocus
                  placeholder="Enter a option"
                  value={quotedOptionName}
                  onChange={(e) => setQuotedOptionName(e.target.value)}
                />
              </div>
            </div>
          )}
          {section === "branch" && (
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 mb-4">
                <p>Branch:</p>
                <Input
                  autoFocus
                  placeholder="Enter a branch"
                  value={branchName}
                  onChange={(e) => setBranchName(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end">
          <Button
            className={cn(buttonVariants({ variant: "ghost" }))}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          {section === "vehicle" && (
            <Button
              disabled={vehicleName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
          {section === "year" && (
            <Button disabled={yearName === ""} onClick={() => handleContinue()}>
              Save
            </Button>
          )}
          {section === "quoted-options" && (
            <Button
              disabled={quotedOptionName === ""}
              onClick={() => handleContinue()}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalAddManagement;
