import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { debounce } from "../../lib/debounce";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { BASE_URL, BASE_URL_IMG } from "../../config/api.config";
import { ShowItem, ShowModal } from "./ManagementGeneral";
import { Pencil, Trash } from "lucide-react";
import ModalAddQuoteOption from "./ModalAddQuoteOption"; // Modal para añadir
import ModalEditQuoteOption from "./ModalEditQuoteOption"; // Modal para editar
import ModalDeleteConfirmation from "./ModalDeleteConfirmation"; // Modal para confirmar eliminación
import { useTokenStore } from "../../hooks/useTokenStore";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CiImport } from "react-icons/ci";
import { useModalBulkCSV } from "src/hooks/use-open-modal-bulk-csv";

interface CardManagementQuoteOptionsProps {
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  showItem: ShowItem;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  selectedQuoteOption: any;
  setSelectedQuoteOption: Dispatch<SetStateAction<any>>;
}

const CardManagementQuoteOptions: React.FC<CardManagementQuoteOptionsProps> = ({
  selectedQuoteOption,
  setSelectedQuoteOption,
  setShowItem,
  setShowModal,
  showItem,
}) => {
  const [inputBranch, setInputBranch] = useState("");
  const handleBranchesSearch = (e: string) => {
    setInputBranch(e);
  };
  const token = useTokenStore((s) => s.token);
  const optimisedSearch = useCallback(debounce(handleBranchesSearch), []);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false); // Estado para mostrar el modal de edición
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Estado para mostrar el modal de eliminación
  const [quotedOptions, setQuotedOptions] = useState<any[]>([]);
  const [optionToEdit, setOptionToEdit] = useState<any>(null); // Estado para la opción a editar
  const [optionToDelete, setOptionToDelete] = useState<any>(null); // Estado para la opción a eliminar
  const modalUploadBulk = useModalBulkCSV();

  const getForm = async () => {
    const data = await fetch(`${BASE_URL}canopy.trays`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setQuotedOptions(data.result.rows);
      })
      .catch((err) => {
        console.log(err);
      });

    return data;
  };

  useEffect(() => {
    getForm();
  }, []);

  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between mb-4 cursor-pointer">
        <div className="flex items-center">
          <p className="font-bold text-lg">Quoted Option</p>
          <Input
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11 `}
            placeholder="Search Option"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
          <div className="flex items-center space-x-2">
            <Button onClick={() => setShowAddModal(true)} type="button">
              + Add a Option
            </Button>
            <Button
              variant={"outline"}
              onClick={() => modalUploadBulk.onOpen("modalBulkCsv", "product")}
              type="button"
            >
              <CiImport />
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white border flex justify-between rounded ">
        <div className="w-full p-4 text-sm h-96 overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full overflow-auto ">
            {quotedOptions.map((option, i) => (
              <Accordion key={option.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${i}-content`}
                  id={`panel${i}-header`}
                >
                  <Typography>{option.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {option.img_path && (
                    <img
                      src={`${BASE_URL_IMG}${option.img_path}`}
                      alt={option.name}
                      className="mb-4 w-56 h-auto"
                    />
                  )}
                  <p>
                    SKU: <b>{option.sku || "---"}</b>
                  </p>
                  <div className="flex justify-start my-4">
                    <button
                      type="button"
                      className="mr-2 bg-black hover:bg-slate-700 py-2 px-3 rounded-md text-white"
                      onClick={() => {
                        setOptionToEdit(option); // Establece la opción a editar
                        setShowEditModal(true); // Muestra el modal de edición
                      }}
                    >
                      <Pencil className="h-4 w-4" />
                    </button>
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-black py-2 px-3 rounded-md text-white"
                      onClick={() => {
                        setOptionToDelete(option); // Establece la opción a eliminar
                        setShowDeleteModal(true); // Muestra el modal de eliminación
                      }}
                    >
                      <Trash className="h-4 w-4" />
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddQuoteOption
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          refreshOptions={getForm}
        />
      )}
      {showEditModal && optionToEdit && (
        <ModalEditQuoteOption
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          refreshOptions={getForm}
          selectedOption={optionToEdit} // Pasa la opción a editar al modal
        />
      )}
      {showDeleteModal && optionToDelete && (
        <ModalDeleteConfirmation
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          optionToDelete={optionToDelete} // Pasa la opción a eliminar al modal
          refreshOptions={getForm}
        />
      )}
    </div>
  );
};

export default CardManagementQuoteOptions;
