import React, { useEffect, useState } from "react";
import { BASE_URL } from "src/config/api.config";
import { useTokenStore } from "src/hooks/useTokenStore";
import { Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CiExport } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths, format } from "date-fns";
import { enGB } from "date-fns/locale";
import loader from "../../lottie/Animation - 1726067353875.json";
import Lottie from 'react-lottie';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  ChartDataLabels
);

export const TableStats = () => {
  const token = useTokenStore((s) => s.token);
  const [stats, setStats] = useState<any>(null);

  // Obtener la fecha actual y calcular el rango predeterminado (últimos 6 meses)
  const today = new Date();
  const defaultEndDate = addMonths(today, 0);
  const defaultStartDate = addMonths(today, -6);

  // Guardar fechas en los estados con los valores predeterminados
  const [dateStart, setDateStart] = useState<Date | null>(defaultStartDate);
  const [dateEnd, setDateEnd] = useState<Date | null>(defaultEndDate);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  
  const getStats = async () => {
    try {
      const url = `${BASE_URL}quotes.statistics?date_start=${format(
        dateStart!,
        "yyyy-MM-dd"
      )}&date_end=${format(dateEnd!, "yyyy-MM-dd")}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const data = await response.json();
      setStats(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStats(); // Fetch stats on load and whenever date range changes
  }, [dateStart, dateEnd]);

  const getTotalForMonth = (ranges: any[], month: string) => {
    const range = ranges.find((r) => r.month_range === month);
    return range ? range.total : 0;
  };

  const exportToCSV = () => {
    if (!stats) return;

    const { header, products, footer } = stats.result;

    let csvContent = "data:text/csv;charset=utf-8,";

    // Adding headers
    csvContent += header.join(",") + "\n";

    // Adding product rows
    products.forEach((product) => {
      const row = [
        product.name,
        ...header
          .slice(1, -1)
          .map((month) => getTotalForMonth(product.ranges, month)),
        product.total,
      ];
      csvContent += row.join(",") + "\n";
    });

    // Adding footer (totals)
    csvContent += footer.join(",") + "\n";

    // Creating a download link for the CSV
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table_stats.csv");
    document.body.appendChild(link);

    link.click();
  };

  if (
    !stats ||
    !stats.result ||
    !stats.result.products ||
    stats.result.products.length === 0
  ) {
    return <Lottie options={defaultOptions} height={400} width={400} />;
  }

  const { header, products, footer, pie_chart, rangePrices } = stats.result;

  const pieData = {
    labels: false,
    datasets: [
      {
        data: pie_chart.values,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  };

  const pieOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "left",
      },
      datalabels: {
        formatter: (value, context) => {
          const total = context.chart._metasets[0].total;
          const percentage = ((value / total) * 100).toFixed(1) + "%";
          return percentage;
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 14,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    elements: {
      arc: {
        borderWidth: 2,
      },
    },
    width: 200,
    height: 200,
  };

  const lineData = {
    labels: header.slice(1, -1),
    datasets: products.map((product, index) => ({
      label: product.name,
      data: header
        .slice(1, -1)
        .map((month) => getTotalForMonth(product.ranges, month)),
      backgroundColor: pieData.datasets[0].backgroundColor[index],
      borderColor: pieData.datasets[0].backgroundColor[index],
      fill: true,
      tension: 0.1,
    })),
  };

  const lineOptions = {
    width: 300,
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        position: "left",
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
  };

  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-2/3 mb-0 md:mb-0">
          <div className="flex gap-4">
            <div className="p-4 rounded-2xl w-1/3 my-4 shadow-md bg-slate-50 text-black flex items-center">
              <p className="flex items-center gap-8">
                Total Quotes:
                <b className="text-xl flex items-center">
                  {footer[footer.length - 1]}
                </b>
              </p>
            </div>
            <div className="p-4 rounded-2xl w-1/3 my-4 shadow-md bg-slate-50 text-black flex items-center">
              <p className="flex items-center gap-8">
                Most Quoted:
                <b className="text-md flex items-center">{products[0].name}</b>
              </p>
            </div>
            <div className="p-4 rounded-2xl w-1/3 my-4 shadow-md bg-slate-50 text-black flex items-center">
              <p className="flex items-center gap-2">
                Range Prices:
                <b className="text-xl flex items-center">
                  {rangePrices[0].price_range}$
                </b>
                {"(" + rangePrices[0].quote_count + ")"}
              </p>
            </div>
          </div>

          <div className="pt-4 px-10 pb-10 rounded-2xl my-4 shadow-md bg-slate-50 text-black">
            <div className="flex items-center gap-4 pb-4 w-full justify-between">
              {/* Input para seleccionar fecha de inicio */}
              <div className="flex gap-2">
                <DatePicker
                  selected={dateStart}
                  onChange={(date) => setDateStart(date)}
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300 px-4 py-2 rounded-lg bg-white"
                  locale={enGB} // Para usar formato dd/MM/yyyy
                />

                {/* Input para seleccionar fecha de fin */}
                <DatePicker
                  selected={dateEnd}
                  onChange={(date) => setDateEnd(date)}
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300 px-4 py-2 rounded-lg bg-white"
                  locale={enGB} // Para usar formato dd/MM/yyyy
                />
              </div>

              <button
                className="bg-black hover:bg-gray-700 text-white px-4 py-2 rounded-lg shadow-md flex gap-2 items-center"
                onClick={exportToCSV}
              >
                Export to CSV
                <CiExport />
              </button>
            </div>
            <table className="min-w-full border border-gray-300 rounded-md shadow-md">
              <thead>
                <tr>
                  {header.map((head, index) => (
                    <th
                      key={index}
                      className="border border-gray-300 px-4 py-2 text-center font-bold"
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {products.map((product, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="border border-gray-300 px-4 py-2">
                      {product.name}
                    </td>
                    {header.slice(1, -1).map((month, colIndex) => (
                      <td
                        key={colIndex}
                        className="border border-gray-300 px-4 py-2 text-center"
                      >
                        {getTotalForMonth(product.ranges, month)}
                      </td>
                    ))}
                    <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                      {product.total}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  {footer.map((foot, index) => (
                    <td
                      key={index}
                      className="border border-gray-300 px-4 py-2 text-center font-bold"
                    >
                      {foot}
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div className="p-10 rounded-2xl w-[full] h-[300px] flex justify-between shadow-md bg-slate-50 text-black">
        <div className="w-2/3">
          <Line data={lineData} options={lineOptions} />
        </div>
        <div className="w-1/3">
          <Pie data={pieData} options={pieOptions} />
        </div>
      </div>
    </div>
  );
};
