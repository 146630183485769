import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useModalOptions } from "../../hooks/use-open-modal-options";
import { FormTypes } from "../../types";
import { URL } from "../../config/api.config";
import { IoIosClose } from "react-icons/io";
import { MdFormatQuote } from "react-icons/md";
import { LuImagePlus, LuPlus } from "react-icons/lu";

interface PreviewFormsProps {
  selectForm: {
    type: string;
    id: string;
    question: string;
    optional: string;
  };
  formData: FormTypes;
  setSelectForm: Dispatch<SetStateAction<any>>;
}

const PreviewForms = ({
  selectForm,
  setSelectForm,
  formData,
}: PreviewFormsProps) => {
  const modalOptions = useModalOptions();
  const [inputs, setInputs] = useState([{ option: "" }]);
  const [imageInputs, setImageInputs] = useState([{ image: "" }]);

  const handleAddInput = () => {
    setInputs([...inputs, { option: "" }]);
  };

  const handleAddInputImage = () => {
    setImageInputs([...imageInputs, { image: "" }]);
  };

  const handleChange = (event: any, index: number) => {
    const { name, value } = event.target;
    const onChangeValue: any = [...inputs];
    onChangeValue[index][name] = value;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index: number) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  return (
    <div className="w-full flex-grow h-full">
      <div className="mx-auto w-full h-full flex items-center justify-center">
        {selectForm?.type === "entry" && (
          <div className="bg-black h-96 w-[700px] p-10 px-20 overflow-y-auto">
            <div className="flex flex-col items-center justify-center ">
              <h1 className="text-white text-5xl font-semibold text-center">
                Instant Tray & Canopy Quote Form
              </h1>
              <h3 className="text-xl font-medium italic text-gray-500 mt-4">
                Description (optional)
              </h3>
              <button className="bg-white text-[#0058e5] font-medium px-4 py-1 rounded-full mt-4">
                <h1 className="text-xl font-semibold">Start</h1>
              </button>
              <div className="flex items-center space-x-2 mt-4">
                <FaRegClock className="h-4 w-4 fill-white" />
                <p className="font-medium text-white text-[12px]">
                  Takes X minutes
                </p>
              </div>
            </div>
          </div>
        )}
        {selectForm?.type === "final" && (
          <div className="bg-black h-96 overflow-y-auto w-[700px] p-10 px-20">
            <div className="flex flex-col space-y-5">
              <h1 className="text-white font-semibold text-4xl text-center">
                {selectForm.question}
              </h1>
              <h3 className="text-gray-500 font-medium text-center">
                {selectForm.optional}
              </h3>
            </div>
          </div>
        )}
        {selectForm?.type !== "entry" && selectForm?.type !== "final" && (
          <div className="bg-black h-96 overflow-y-auto w-[700px] p-10 px-20">
            {selectForm?.id === "" ? (
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2">
                  <h1 className="text-[#FBCE37] text-[14px] font-medium">1</h1>
                  <BsArrowRight className="h-4 w-4 text-[#FBCE37]" />
                </div>
                <div className="flex flex-col items-start mt-4">
                  <h1 className="text-white text-2xl font-semibold">
                    Please tell us a bit about yourself*
                  </h1>
                  <h3 className="text-gray-500 italic font-medium">
                    Description (optional)
                  </h3>
                </div>
              </div>
            ) : (
              <>
                {selectForm?.type === "message" ? (
                  <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                      <MdFormatQuote className="h-5 w-5 text-[#FBCE37] rotate-180" />
                    </div>
                    <div className="flex flex-col items-start mt-4"></div>
                  </div>
                ) : (
                  <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                      <h1 className="text-[#FBCE37] text-[14px] font-medium">
                        {formData?.questions?.[0].question_id.replace("q", "")}
                      </h1>
                      <BsArrowRight className="h-4 w-4 text-[#FBCE37]" />
                    </div>
                    <div className="flex flex-col items-start mt-4">
                      <h1 className="text-white text-2xl font-semibold">
                        {formData?.questions?.[0].text}*
                      </h1>
                      <h3 className="text-gray-500 italic font-medium">
                        {formData?.questions?.[0].optional_detail ??
                          "Description (optional)"}
                      </h3>
                    </div>
                  </div>
                )}
              </>
            )}

            {selectForm?.type === "multiple_selection" && (
              <div className="flex flex-col items-start mt-4 space-y-2">
                {formData?.options?.map((option, i: number) => (
                  <div
                    key={option.id}
                    className="bg-[#FBCE37]/15 px-4 py-1 min-w-60 border-[#FBCE37] border rounded-full flex items-stretch space-x-2"
                  >
                    <h1 className="text-[#FBCE37] border border-[#FBCE37]/50 h-5 w-5 rounded-full text-sm flex items-center justify-center text-[13px]">
                      {letters.split("").map((letter, index) => {
                        return <p key={letter}>{index === i && letter}</p>;
                      })}
                      {/* {i === 0 && "A"}
                      {i === 1 && "B"}
                      {i === 2 && "C"}
                      {i === 3 && "D"}
                      {i === 4 && "E"}
                      {i === 5 && "F"} */}
                    </h1>
                    <h1 className="text-[#FBCE37] font-medium text-[15px] capitalize">
                      {option.option_text}
                    </h1>
                  </div>
                ))}
                {inputs.map((input, i) => (
                  <div
                    className="bg-[#FBCE37]/15 px-4 py-1 min-w-60 border-[#FBCE37] border rounded-full flex items-stretch space-x-2 group relative"
                    key={i}
                  >
                    <h1 className="text-[#FBCE37] border border-[#FBCE37]/50 h-5 w-5 rounded-full text-sm flex items-center justify-center text-[13px]">
                      {/* {index + 1 + formData.options.length === 6 && "G"}
                      {index + 1 + formData.options.length === 7 && "H"} */}
                      {letters.split("").map((letter, index) => {
                        return (
                          <p key={letter}>
                            {index === i + 1 + formData?.options?.length &&
                              letter}
                          </p>
                        );
                      })}
                    </h1>
                    <input
                      className="text-[#FBCE37] font-medium text-[15px] capitalize bg-transparent outline-none"
                      name="option"
                      type="text"
                      value={input.option}
                      onChange={(event) => handleChange(event, i)}
                    />
                    <div
                      onClick={() => handleDeleteInput(i)}
                      className="absolute top-1 bg-black cursor-pointer -right-2 h-5 w-5 z-20 rounded-full flex items-center justify-center border border-white"
                    >
                      <IoIosClose className="h-4 w-4 text-white" />
                    </div>
                  </div>
                ))}
                <div
                  onClick={() => handleAddInput()}
                  className="cursor-pointer group"
                >
                  <h1 className="group-hover:text-[#FBCE37]/80 text-[#FBCE37] text-[15px] underline font-medium ">
                    Add option
                  </h1>
                </div>
              </div>
            )}
            {selectForm?.type === "selector" && (
              <div className="flex flex-col items-start mt-4 space-y-2 w-full">
                <div
                  onClick={() =>
                    modalOptions.onOpen("modalOptions", formData?.options)
                  }
                  className="flex items-center justify-between w-full border-b-[0.2px] pb-2 border-[#FBCE37]/20"
                >
                  <h1 className="text-[#FBCE37]/30 text-2xl font-semibold w-full">
                    Type or select an option
                  </h1>
                  <HiOutlineChevronDown className="h-5 w-5 text-[#FBCE37]" />
                </div>
                <div className="flex items-center justify-between w-full">
                  <p className="text-[#FBCE37] text-sm underline">
                    Edit options
                  </p>
                  <p className="text-[#FBCE37]/70 text-sm">
                    {formData?.options?.length} options in the list
                  </p>
                </div>
              </div>
            )}
            {selectForm?.type === "selector_with_images" && (
              <div className="flex flex-col items-start mt-4 space-y-2 w-full  px-10">
                <div className="grid grid-cols-2 gap-2">
                  {formData?.options.map((option, i: number) => (
                    <div
                      key={option.id}
                      className="border border-[#FBCE37] rounded p-1"
                    >
                      <img
                        src={`${URL}${option.img_path}`}
                        alt=""
                        className="h-28 w-full object-cover "
                      />
                      <div className="flex items-center space-x-2">
                        <h1 className="text-[#FBCE37] border border-[#FBCE37]/50 h-5 w-5 text-[12px] rounded-full text-sm flex items-center justify-center ">
                          {/* {i === 0 && "A"}
                          {i === 1 && "B"}
                          {i === 2 && "C"}
                          {i === 3 && "D"}
                          {i === 4 && "E"} */}
                          {letters.split("").map((letter, index) => {
                            return (
                              <p key={letter}>{index === i + 1 && letter}</p>
                            );
                          })}
                        </h1>
                        <h1 className="text-[#FBCE37] font-medium text-[12px] py-2 capitalize">
                          {option.option_text}
                        </h1>
                      </div>
                    </div>
                  ))}
                  {imageInputs.map((input, i) => (
                    <div
                      key={input.image}
                      className="border border-[#FBCE37] rounded p-1 h-38 flex items-center justify-center"
                    >
                      <LuImagePlus className="h-5 w-5 text-[#FBCE37]" />
                    </div>
                  ))}
                  <div
                    onClick={() => handleAddInputImage()}
                    className="border border-[#FBCE37] rounded p-1 flex items-center justify-center"
                  >
                    <LuPlus className="h-5 w-5 text-[#FBCE37]" />
                  </div>
                </div>
              </div>
            )}
            {selectForm?.type === "message" && (
              <div className="flex flex-col items-start space-y-2 w-full px-4">
                <h1 className="text-white text-2xl font-semibold text-left">
                  We only have Trays available to suit Toyota Landcruiser.
                </h1>
                <h1 className="text-white text-2xl font-semibold text-left">
                  These will require a higher headboard. We will update your
                  order to include this in the price
                </h1>
                <h3 className="text-gray-500 font-medium italic ">
                  Description (optional)
                </h3>
                <button className="bg-white text-[#0058e5] font-medium px-4 py-1 rounded-full mt-4">
                  Continue
                </button>
              </div>
            )}
            {selectForm?.type === "address" && (
              <div className="flex flex-col items-start space-y-6 w-full px-4 mt-4">
                <div className="flex flex-col space-y-2 w-full">
                  <h1 className="text-[#FBCE37] text-sm font-medium">
                    Address *
                  </h1>
                  <input
                    readOnly
                    placeholder="Apartment 4"
                    className="bg-transparent outline-none placeholder:text-[#FBCE37]/40 placeholder:text-sm h-6 px-2 font-medium  border-[#FBCE37] w-full border-b-[0.5px] space-x-2"
                  ></input>
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <h1 className="text-[#FBCE37] text-sm font-medium">
                    Address line 2 *
                  </h1>
                  <input
                    className="bg-transparent outline-none placeholder:text-[#FBCE37]/40 placeholder:text-sm h-6 px-2 font-medium  border-[#FBCE37] w-full border-b-[0.5px] space-x-2"
                    placeholder="Palo/Alto"
                  ></input>
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <h1 className="text-[#FBCE37] text-sm font-medium">
                    City/Town *
                  </h1>
                  <input
                    className="bg-transparent outline-none placeholder:text-[#FBCE37]/40 placeholder:text-sm h-6 px-2 font-medium  border-[#FBCE37] w-full border-b-[0.5px] space-x-2"
                    placeholder="California"
                  ></input>
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <h1 className="text-[#FBCE37] text-sm font-medium">
                    State/Region/Province *
                  </h1>
                  <input
                    className="bg-transparent outline-none placeholder:text-[#FBCE37]/40 placeholder:text-sm h-6 px-2 font-medium  border-[#FBCE37] w-full border-b-[0.5px] space-x-2"
                    placeholder="California"
                  ></input>
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <h1 className="text-[#FBCE37] text-sm font-medium">
                    Zip/Post code *
                  </h1>
                  <input
                    placeholder="94304"
                    className="bg-transparent outline-none placeholder:text-[#FBCE37]/40 placeholder:text-sm h-6 px-2 font-medium  border-[#FBCE37] w-full border-b-[0.5px] space-x-2"
                  ></input>
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <h1 className="text-[#FBCE37] text-sm font-medium">
                    Country *
                  </h1>
                  <input
                    placeholder="United States"
                    className="bg-transparent outline-none placeholder:text-[#FBCE37]/40 placeholder:text-sm h-6 px-2 font-medium  border-[#FBCE37] w-full border-b-[0.5px] space-x-2"
                  ></input>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewForms;
