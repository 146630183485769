import React, { useEffect, useState } from "react";
import { Main } from "src/components/Main/Main";
import { BASE_URL, SERVICES } from "src/config/api.config";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Menu,
  Button,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaCheckCircle, FaPlay, FaStop } from "react-icons/fa";
import { MdCancel, MdError } from "react-icons/md";

const statusColors = {
  200: "green",
  400: "red",
};

export const Services = () => {
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const token = localStorage.getItem("token_form_pirahna");

  const getServices = async () => {
    try {
      const response = await fetch(`${BASE_URL}${SERVICES}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const data = await response.json();

      setServices(data?.result.monitor || []);
      setFilteredServices(data?.result.monitor || []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    filterServices();
  }, [searchName, filterStatus, services]);

  const filterServices = () => {
    const filtered = services.filter((service) => {
      return (
        service.name_service.toLowerCase().includes(searchName.toLowerCase()) &&
        (filterStatus === "" || service.status === filterStatus)
      );
    });

    // Si no se encuentra ningún resultado, se muestran todos los servicios
    if (filtered.length === 0) {
      setFilteredServices(services);
    } else {
      setFilteredServices(filtered);
    }
  };

  const renderResults = (results) => {
    const resultArray = results.split(",");
    const numberOfResults = 15;

    const displayResults = [
      ...resultArray,
      ...Array(Math.max(numberOfResults - resultArray.length, 0)).fill("0"),
    ];
    
  
    return displayResults.map((result, index) => {
      const color = statusColors[result] || "black";
      return (
        <span
          key={index}
          style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: color,
            margin: "0 2px",
          }}
          title={`Status: ${result}`}
        />
      );
    });
  };

  const renderStatusIcon = (status) => {
    if (status === "1") {
      return <CheckIcon style={{ color: "green" }} />;
    } else if (status === "2") {
      return <CloseIcon style={{ color: "red" }} />;
    }
    return null;
  };

  const renderLastResult = (last_run_result_id) => {
    if (last_run_result_id === "200") {
      return <p>Working</p>;
    } else if (last_run_result_id === "400") {
      return <p>Error</p>;
    }
    return null;
  };

  // Función para determinar el estado general en función del último resultado en last_results
  const statusMessage = () => {
    const lastResults = services.map((service) => {
      const resultsArray = service.last_results.split(",");
      return resultsArray[resultsArray.length - 1];
    });

    const allGreen = lastResults.every((result) => result === "200");
    const allRed = lastResults.every((result) => result === "400");
    const someRed = !allGreen && !allRed;

    if (allGreen) {
      return {
        color: "green",
        icon: <FaCheckCircle style={{ marginLeft: "8px" }} />,
        text: "All Services Up and Running",
      };
    } else if (allRed) {
      return {
        color: "red",
        icon: <MdCancel style={{ marginLeft: "8px" }} />,
        text: "All Services Error",
      };
    } else if (someRed) {
      return {
        color: "orange",
        icon: <MdError style={{ marginLeft: "8px" }} />,
        text: "Some Services Error",
      };
    }
    return { color: "black", icon: null, text: "Unknown Status" };
  };

  const { color, text, icon } = statusMessage();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Main>
      <div className="p-10 flex justify-center w-full">
        <div>
          <div>
            <p className="text-black text-2xl font-bold">Services</p>
            <hr className=" bg-slate-200 h-1 rounded-full" />
          </div>
          <div className="h-20 w-40 bg-white rounded-md my-4 p-4 flex items-center">
            <p>
              Monitors: <b>{services.length}</b>
            </p>
          </div>
          <div className="flex items-center justify-between bg-white px-2 rounded-sm">
            <div
              className={`h-10 w-[300px] rounded-md my-4 p-4 flex items-center justify-center shadow-md`}
              style={{ backgroundColor: color, opacity: 0.7, color: "white" }}
            >
              <p
                style={{ display: "flex", alignItems: "center" }}
                className="gap-2"
              >
                {icon} {/* Renderiza el icono correspondiente */}
                {text}
              </p>
            </div>
            <div className="flex items-center gap-4 ">
              <TextField
                label="Search by Name"
                variant="outlined"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                size="small"
              />
              <FormControl variant="outlined" size="small">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  label="Status"
                  style={{ width: "200px" }}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value="1">Enable</MenuItem>
                  <MenuItem value="2">Disable</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {filteredServices.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Name Service</TableCell>
                    <TableCell>Last Run Date</TableCell>
                    <TableCell>Last Run Result ID</TableCell>
                    <TableCell>Last Run Result Details</TableCell>
                    <TableCell>Last Failed Date</TableCell>
                    <TableCell>Last Results</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredServices.map((service) => (
                    <TableRow key={service.service_id}>
                      <TableCell>{renderStatusIcon(service.status)}</TableCell>
                      <TableCell>{service.name_service}</TableCell>
                      <TableCell>{service.last_run_date}</TableCell>
                      <TableCell>
                        {renderLastResult(service.last_run_result_id)}
                      </TableCell>
                      <TableCell>
                        {service.last_run_result_details || "no details"}
                      </TableCell>
                      <TableCell>{service.last_failed_date}</TableCell>
                      <TableCell>
                        {renderResults(service.last_results)}
                      </TableCell>
                      <TableCell>
                        <Button
                          id="basic-button"
                          color="inherit"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          className="w-8 h-8"
                        >
                          <HiDotsHorizontal size={20} />
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={handleClose}>
                            <FaPlay className="mr-2" />
                            Start service
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <FaStop color="red" className="mr-2" />
                            Stop service
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No services available.</Typography>
          )}
        </div>
      </div>
    </Main>
  );
};
