import React, { ReactNode } from "react";
import "animate.css";

// COMPONENTS
import SideNavBar from "../Navbar/SideNavbar";

// STYLES
import { MainStyles } from "./Main.styles";

interface MainProps {
  children: ReactNode;
}

export const Main = ({ children }: MainProps) => {
  return (
    <MainStyles>
      <SideNavBar className="">{children}</SideNavBar>
    </MainStyles>
  );
};
