import React from "react";
import { HiPencil } from "react-icons/hi";
import { FormTypes } from "../../types";
import { FaCircleChevronDown } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { Done } from "@mui/icons-material";
import { MdOutlineCancel, MdOutlineDone } from "react-icons/md";

interface ModalFormTypes {
  form: FormTypes;
}

const FormDetailsQuestionsRow = ({ form }: ModalFormTypes) => {
  const [changeQuestion, setChangeQuestion] = useState(false);
  const [valueQuestion, setValueQuestion] = useState("");
  const [changeResponse, setChangeResponse] = useState(false);
  const [valueResponse, setValueResponse] = useState("");

  useEffect(() => {
    setValueQuestion(form.text);
    setValueResponse(form.option_text);
  }, []);

  const updateQuestion = () => {
    console.log("q");
  };
  const updateResponse = () => {
    console.log("r");
  };
  return (
    <div className="">
      <div className="flex flex-col border rounded-b py-4 px-2 space-y-2">
        <div>
          <label className="text-sm font-medium text-gray-500">Question</label>
          <h3 className="font-medium items-center justify-between text-gray-600 bg-gray-100 flex px-2 rounded mt-1 py-2">
            {form.text}
            <div
              onClick={() => setChangeQuestion(true)}
              className="hover:bg-gray-200 h-7 w-7 transition  flex items-center justify-center rounded-full cursor-pointer"
            ></div>
          </h3>
          {changeQuestion && (
            <div className="relative">
              <input
                onChange={(e) => setValueQuestion(e.target.value)}
                value={valueQuestion}
                className="border w-full mt-2 rounded h-8 px-2"
              />
              <MdOutlineDone
                onClick={() => updateQuestion()}
                className="h-5 w-5 absolute top-[14px] right-10 cursor-pointer"
              />
              <MdOutlineCancel
                onClick={() => setChangeQuestion(false)}
                className="h-5 w-5 absolute top-[14px] right-2 cursor-pointer"
              />
            </div>
          )}
        </div>
        <div>
          <label className="text-sm font-medium text-gray-500">Response</label>
          <h3 className="font-medium items-center justify-between text-gray-600 bg-gray-100 flex px-2 rounded mt-1 py-2">
            {form.option_text}
            <div
              onClick={() => setChangeResponse(true)}
              className="hover:bg-gray-200 h-7 w-7 transition  flex items-center justify-center rounded-full cursor-pointer"
            ></div>
          </h3>
          {changeResponse && (
            <div className="relative">
              <input
                onChange={(e) => setValueResponse(e.target.value)}
                value={valueResponse}
                className="border w-full mt-2 rounded h-8 px-2"
              />
              <MdOutlineDone
                onClick={() => updateResponse()}
                className="h-5 w-5 absolute top-[14px] right-10 cursor-pointer"
              />
              <MdOutlineCancel
                onClick={() => setChangeResponse(false)}
                className="h-5 w-5 absolute top-[14px] right-2 cursor-pointer"
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center mt-2">
        <FaCircleChevronDown className="h-6 w-6 text-gray-500" />
      </div>
    </div>
  );
};

export default FormDetailsQuestionsRow;
