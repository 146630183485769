import React from "react";
import {
  // RouterProvider,
  // createBrowserRouter,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { Login } from "../Screens/Login";
import { PrivateRoute } from "./PrivateRoute";
import Metrics from "../Screens/Metrics";
import Statistics from "../Screens/Statistics";
import Settings from "../Screens/Settings";
import Quotes from "../Screens/Quotes";
import { Offices } from "../Screens/Offices";
import { Prices } from "../Screens/Prices";
import ManagementGeneral from "../components/Management/ManagementGeneral";
import { Services } from "src/Screens/Services";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Statistics />} />
          <Route path="/quotes" element={<Quotes />} />
          <Route path="/forms" element={<Metrics />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/prices" element={<Prices />} />
          <Route path="/management" element={<ManagementGeneral />} />
          <Route path="/offices" element={<Offices />} />
          <Route path="/services" element={<Services />} />
        </Route>
        {/* Login */}
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}
