import React from "react";
import { useNavigate } from "react-router-dom";
import { UserBoxStyles } from "./UserBox.styles";
import { FaUser } from "react-icons/fa";
import { useTokenStore } from "../../hooks/useTokenStore";
import { IoExitOutline } from "react-icons/io5";
import { useUserInfo } from "../../hooks/useUserInfo";

const UserBox = () => {
  const navigate = useNavigate();
  const setToken = useTokenStore((s) => s.setToken);
  const userInfo = useUserInfo((s) => s.userInfo);

  return (
    <UserBoxStyles data-testid="UserBox" className=" mt-2">
      <div className="dropdown dropdown-end z-50">
        <div tabIndex={0} className="avatar online cursor-pointer">
          <div className="rounded-full h-10 w-10 ">
            <FaUser className="h-7 w-7 text-white mt-1 ml-2" />
          </div>
        </div>
        <div
          tabIndex={0}
          className="shadow menu dropdown-content bg-base-100 rounded-box w-64 p-4"
        >
          <div className="flex flex-col gap-4">
            <div className="text-ms font-medium">
              Admin <p>{userInfo}</p>
            </div>

            <div
              className="p-2 hover:bg-gray-200 rounded cursor-pointer"
              onClick={() => navigate("/settings")}
            >
              <p>Settings</p>
            </div>
          </div>
          <div className="divider"></div>
          <div
            onClick={() => {
              setToken("");
              navigate("/login");
            }}
            className="cursor-pointer flex gap-4 p-2 hover:bg-gray-200 rounded"
          >
            <IoExitOutline color="red" className="h-6 w-6" />
            <p>{"Logout"}</p>
          </div>
        </div>
      </div>
    </UserBoxStyles>
  );
};

export default UserBox;
