import React, { useState } from "react";
import { Button } from "../ui/Button";
import { BASE_URL } from "../../config/api.config";
import { useTokenStore } from "../../hooks/useTokenStore";

interface ModalAddQuoteOptionProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshOptions: () => void; // Función para refrescar las opciones después de añadir una nueva
}

const ModalAddQuoteOption: React.FC<ModalAddQuoteOptionProps> = ({
  showModal,
  setShowModal,
  refreshOptions,
}) => {
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [imgPath, setImgPath] = useState<File | null>(null); // Estado para la imagen
  const token = useTokenStore((s) => s.token);

  const handleAddOption = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("sku", sku);
    if (imgPath) {
      formData.append("img_path", imgPath); // Agregar la imagen al FormData
    }
    formData.append("_method", "POST");

    await fetch(`${BASE_URL}canopy.trays`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Option added:", data);
        setShowModal(false);
        refreshOptions(); // Refrescar las opciones después de añadir
      })
      .catch((err) => {
        console.error("Error adding option:", err);
      });
  };

  return (
    showModal && (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 w-96">
          <h2 className="text-xl font-bold mb-4">Add a New Option</h2>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Option Name"
            className="border border-gray-300 rounded w-full px-3 py-2 mb-4 bg-white"
          />
          <input
            type="file"
            onChange={(e) => {
              if (e.target.files) {
                setImgPath(e.target.files[0]);
              }
            }}
            className="border border-gray-300 rounded w-full px-3 py-2 mb-4"
          />
          <input
            type="text"
            value={sku}
            onChange={(e) => setSku(e.target.value)}
            placeholder="Product SKU"
            className="border border-gray-300 rounded w-full px-3 py-2 mb-4 bg-white"
          />
          <div className="flex justify-end gap-2">
            <Button
              onClick={() => setShowModal(false)}
              type="button"
              className="bg-gray-500 hover:bg-gray-600 text-white"
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddOption}
              type="button"
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalAddQuoteOption;
