import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { SideNavBarStyles } from "./SideNavbar.styles";
import Logo from "../../assets/Piranha logo.avif";
import AppConfig from "../../config/app.config";
import { cn } from "../../lib/utils";
import SideMenuBtns from "./SideMenuBtns";
import { BsBell } from "react-icons/bs";
import NotificationAlerts from "../Notifications/NotificationAlerts";
import UserBox from "../Userbox/UserBox";
import { BASE_URL, QUOTES } from "../../config/api.config";
import { useNavigate } from "react-router-dom";

interface SideNavbarProps {
  children: React.ReactNode;
  className?: string;
}

const SideNavbar = ({ children, className }: SideNavbarProps) => {
  const token = localStorage.getItem("PiranhaToken");

  const { windowWidth } = useWindowDimensions();
  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);
  const notificationBox = useRef<any>(null);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const navigate = useNavigate();

  return (
    <SideNavBarStyles>
      <div className="min-h-screen w-full bg-white relative flex ap-sidebar">
        <SideMenuBtns />

        <div className="w-full desktop:overflow-y-auto flex flex-col laptop:justify-start">
          <header className="h-16 w-full flex items-center relative justify-between px-5 space-x-10 admin-piranha-navbar">
            {windowWidth > 750 && (
              <div className="flex justify-between items-center gap-4">
                {/* Website Logo */}
                <div className="cursor-pointer" onClick={() => navigate("/")}>
                  <img
                    src={Logo}
                    title={AppConfig.appName}
                    alt="logo"
                    className="h-10"
                  />
                </div>
              </div>
            )}
            <div className="flex flex-shrink-0 items-center space-x-4 flex-wrap ml-auto laptop:ml-0">
              <div className="my-6 indicator" ref={notificationBox}>
                {notificationsCount > 0 && (
                  <div className="indicator-item badge badge-secondary mt-2">
                    {notificationsCount}
                  </div>
                )}
                <div
                  className="btn-sx btn btn-ghost m-0 p-1 relative"
                  title="My Notifications"
                >
                  {notificationsCount > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 10000,
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#ffff",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </div>
              </div>

              <UserBox />
            </div>
          </header>
          <main className="max-w-full h-full flex px-1 py-1">
            <div
              className={cn("h-full w-full flex flex-wrap gap-4", className)}
            >
              {children}
            </div>
          </main>
        </div>
      </div>
    </SideNavBarStyles>
  );
};

export default SideNavbar;
