import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { debounce } from "../../lib/debounce";
import { Input } from "../ui/Input";
import { BASE_URL } from "../../config/api.config";
import { useQuery } from "@tanstack/react-query";
import { ShowItem, ShowModal } from "./ManagementGeneral";
import { Button } from "../ui/Button";
import ModalAddManagement from "./ModalAddManagement";
import { OptionsTypes } from "../../types";
import { Pencil, Trash } from "lucide-react";
import { cn } from "../../lib/utils";
import { useTokenStore } from "../../hooks/useTokenStore";
import { useModalBulkCSV } from "src/hooks/use-open-modal-bulk-csv";
import ModalUploadCSV from "../Modals/ModalUploadCsv";
import { CiImport } from "react-icons/ci";

interface CardManagementVehiclesProps {
  setShowItem: Dispatch<SetStateAction<ShowItem>>;
  showItem: ShowItem;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  selectedVehicle: OptionsTypes;
  setSelectedVehicle: Dispatch<SetStateAction<OptionsTypes>>;
}

const CardManagementVehicles: React.FC<CardManagementVehiclesProps> = ({
  setShowItem,
  showItem,
  setShowModal,
  selectedVehicle,
  setSelectedVehicle,
}) => {
  const [inputVehicle, setInputVehicle] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const token = useTokenStore((s) => s.token);
  const modalUploadBulk = useModalBulkCSV();

  const handleVehicleSearch = (e: string) => {
    setInputVehicle(e);
  };

  const optimisedSearch = useCallback(debounce(handleVehicleSearch), []);

  const getForm = async () => {
    const data = await fetch(`${BASE_URL}vehicles`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return data.result.rows;
  };

  const { data: formData } = useQuery({
    queryKey: ["form"],
    queryFn: getForm,
  });

  const createNewVehicle = () => {
    console.log("first");
  };

  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between mb-4 cursor-pointer">
        <div className="flex items-center">
          <p className="font-bold text-lg">Vehicles</p>
          <Input
            className={`border border-border outline-none text-sm ml-6 h-full w-[205px] bg-white mr-4 px-4 pr-11 `}
            placeholder="Search Vehicle"
            type="text"
            onChange={(e) => optimisedSearch(e.target.value)}
          />
          <div className="flex items-center space-x-2">
            <Button onClick={() => setShowAddModal(true)} type="button">
              + Add a vehicle
            </Button>
            <Button
              variant={"outline"}
              onClick={() => modalUploadBulk.onOpen("modalBulkCsv", "vehicle")}
              type="button"
            >
              <CiImport />
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white border flex justify-between rounded ">
        <div className="w-full p-4 text-sm h-96 overflow-y-auto flex justify-between text-black">
          <div className="flex flex-col w-full overflow-auto">
            {formData?.map(
              (
                option: {
                  id: string;
                  name: string;
                },
                i: number
              ) => (
                <div
                  onMouseEnter={() => {
                    setShowItem((prev) => ({
                      ...prev,
                      trash: i,
                      edit: i,
                      where: "vehicle",
                    }));
                  }}
                  onMouseLeave={() => {
                    setShowItem((prev) => ({
                      ...prev,
                      trash: -1,
                      edit: -1,
                      where: "",
                    }));
                  }}
                  key={option.id}
                  className={cn(
                    " cursor-pointer py-2 border-b px-2 flex justify-between items-center",
                    selectedVehicle.id === option.id && "bg-gray-100"
                  )}
                  onClick={() => {
                    setSelectedVehicle({
                      id: option.id,
                      name: option.name,
                      img_path: null,
                      option_text: "",
                    });
                  }}
                >
                  <p>{option.name}</p>
                  <div className="flex items-center gap-3">
                    {showItem.edit === i && showItem.where === "vehicle" && (
                      <div className="h-[24px] w-[24px] hover:bg-slate-200 flex items-center justify-center rounded-full">
                        <div
                          onClick={() =>
                            setShowModal((prev) => ({
                              ...prev,
                              edit: true,
                              section: "vehicle",
                            }))
                          }
                        >
                          <Pencil className="h-5 w-5 " />
                        </div>
                      </div>
                    )}
                    {showItem.trash === i && showItem.where === "vehicle" && (
                      <div className="h-[24px] w-[24px] hover:bg-slate-200 flex items-center justify-center rounded-full">
                        <div
                          onClick={() =>
                            setShowModal((prev) => ({
                              ...prev,
                              delete: true,
                              edit: false,
                              section: "vehicle",
                            }))
                          }
                          className="h-[20px] w-[22px] hover:bg-slate-200 flex items-center justify-center rounded"
                        >
                          <Trash className="h-5 w-5 " />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {showAddModal && (
        <ModalAddManagement
          handleAction={createNewVehicle}
          section="vehicle"
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          type="add"
          setSelectedVehicle={setSelectedVehicle}
        />
      )}
      {modalUploadBulk.isOpen && <ModalUploadCSV />}
    </div>
  );
};

export default CardManagementVehicles;
