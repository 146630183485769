import React, { useEffect, useState } from "react";
import { Main } from "../components/Main/Main";
import { BASE_URL, QUOTES } from "../config/api.config";
import { useTokenStore } from "../hooks/useTokenStore";
import { useQuery } from "@tanstack/react-query";
import { QuoteTypes, FormTypes } from "../types";
import ModalRowDetailsForm from "../components/Table/ModalRowDetailsForm";
import { reload, selectedQuoteId } from "../atoms";
import { useAtom } from "jotai";
import { MdOutlineSwapVert } from "react-icons/md";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Papa from "papaparse";

import QuoteTableRow from "../components/Table/QuoteTableRow";
import { useModalFormDetails } from "../hooks/use-open-modal-form-details";
import ModalRowFormDetails from "../components/Table/ModalRowFormDetails";
import { CiExport } from "react-icons/ci";
import { IoReload } from "react-icons/io5";
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { INITIAL_STATE } from "src/data";

interface Filters {
  date_from: string | null;
  date_to: string | null;
  due_date: string | null;
  [key: string]: any; // For additional filters like address, name, etc.
}

const Quotes = () => {
  const [reloadState, setReloadState] = useAtom(reload);
  const [selectedQuote, setSelectedQuote] = useState<QuoteTypes>(INITIAL_STATE);
  const [showQuoteFormDetails, setShowQuoteFormDetails] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0); // Initialize with 0
  const [quoteId, setQuoteId] = useAtom(selectedQuoteId);

  const [filters, setFilters] = useState<Filters>({
    address: "",
    email: "",
    name: "",
    phone_number: "",
    state_region_province: "",
    date_mail_sent: "",
    id: "",
    date_from: "",
    date_to: "",
    score: "",
    due_date: "",
  });

  const initialFilters = {
    id: "",
    score: "",
    email: "",
    name: "",
    phone_number: "",
    date_mail_sent: "",
    date_from: "",
    date_to: "",
    due_date: "",
  };

  const [page, setPage] = useState(0); // Página actual de la tabla
  const [rowsPerPage, setRowsPerPage] = useState(10); // Citas por página
  const [order, setOrder] = useState("date_created"); // Columna de ordenación actual
  const [orderSense, setOrderSense] = useState("DESC"); // Sentido de ordenación actual
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc"); // Orden de fecha
  const [selectedColumn, setSelectedColumn] = useState("date_created"); // Columna predeterminada
  const [totalQuotes, setTotalQuotes] = useState(""); // Columna predeterminada
  const [expired, setExpired] = useState("1"); //
  const [limitrows, setLimitRows] = useState("10"); //
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    refetch();
    filterExpireds();
  };
  const handleChange2 = (event: SelectChangeEvent<string>) => {
    const newLimit = event.target.value;
    setLimitRows(newLimit); // Actualiza el estado con el nuevo valor
    refetch(); // Refetch los datos con el nuevo límite
  };
  const [valueFrom, setValueFrom] = React.useState<Dayjs | null>(
    dayjs("2024-08-30")
  );
  const [valueTo, setValueTo] = React.useState<Dayjs | null>(
    dayjs("2024-08-30")
  );
  const [valueDueDate, setValueDueDate] = React.useState<Dayjs | null>(
    dayjs("2024-08-30")
  );

  const modalFormDetails = useModalFormDetails();
  const token = useTokenStore((s) => s.token);
  const getQuotes = async ({ page = 1, limit = limitrows }) => {
    try {
      const response = await fetch(
        `${BASE_URL}${QUOTES}?page=${page}&limit=${limit}&order=${order}&order_sense=${orderSense}&expired=${expired}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.json();
      localStorage.setItem("notifications", data?.paginate.rows);
      // Update the state after receiving the response
      setTotalPages(data?.paginate.pages || 0); // Ensure totalPages has a default value
      setTotalQuotes(data?.paginate.rows);
      return data?.result;
    } catch (err) {
      console.error(err);
    }
  };

  const filterExpireds = () => {
    if (checked === true) {
      setExpired("0");
      getQuotes({ page: page + 1, limit: limitrows });
      refetch();
    } else if (checked === false) {
      setExpired("1");
      getQuotes({ page: page + 1, limit: limitrows });
      refetch();
    }
  };
  useEffect(() => {
    console.log("page", page);
  }, [page]);

  const exportQuotesToCSV = async () => {
    try {
      const quotes = await getCSV(); // Obtener todas las citas

      if (!quotes || quotes.length === 0) {
        alert("No data available for export.");
        return;
      }

      const headers = [
        { label: "Quote ID", key: "id" },
        { label: "Date created", key: "date_created" },
        { label: "Date mail sent", key: "date_mail_sent" },
        { label: "Due date", key: "due_date" },
        { label: "Address", key: "address" },
        { label: "Address Line 2", key: "address_line2" },
        { label: "City", key: "city_town" },
        { label: "Country Code", key: "country_code" },
        { label: "Email", key: "email" },
        { label: "First name", key: "first_name" },
        { label: "Last name", key: "last_name" },
        { label: "Phone number", key: "phone_number" },
        { label: "State", key: "state_region_province" },
        { label: "SKU", key: "SKU" },
        { label: "ZipCode", key: "zip_code" },
        { label: "Price", key: "score" },
        { label: "Fitting price", key: "fitting_price" },
        { label: "Powdercoat price", key: "powdercoat_price" },
        { label: "Main part", key: "main_part_no" },
        { label: "Main part price", key: "main_part_price" },
        { label: "Next gen amarok", key: "next_gen_amarok" },
        { label: "Branch", key: "branch" },
        { label: "Vehicle", key: "vehicle" },
        { label: "Year", key: "year" },
        { label: "Status", key: "status_id" },
        { label: "Form Question", key: "form_question" },
        { label: "Form Response", key: "form_response" },
        { label: "acc_part_no1", key: "acc_part_no1" },
        { label: "acc_part_no2", key: "acc_part_no2" },
        { label: "acc_part_no3", key: "acc_part_no3" },
        { label: "acc_part_no4", key: "acc_part_no4" },
        { label: "acc_part_no5", key: "acc_part_no5" },
        { label: "acc_part_no6", key: "acc_part_no6" },
        { label: "acc_part_no7", key: "acc_part_no7" },
        { label: "acc_part_no8", key: "acc_part_no8" },
        { label: "acc_part_no9", key: "acc_part_no9" },
        { label: "acc_price_no1", key: "acc_price_no1" },
        { label: "acc_price_no2", key: "acc_price_no2" },
        { label: "acc_price_no3", key: "acc_price_no3" },
        { label: "acc_price_no4", key: "acc_price_no4" },
        { label: "acc_price_no5", key: "acc_price_no5" },
        { label: "acc_price_no6", key: "acc_price_no6" },
        { label: "acc_price_no7", key: "acc_price_no7" },
        { label: "acc_price_no8", key: "acc_price_no8" },
        { label: "acc_price_no9", key: "acc_price_no9" },
      ];

      const csv = Papa.unparse(
        {
          fields: headers.map((header) => header.key),
          data: quotes.map((quote: any) => ({
            ...quote,
            form_question: "", // Completa si tienes detalles de formulario.
            form_response: "", // Completa si tienes detalles de formulario.
          })),
        },
        {
          header: true,
        }
      );

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `quotes-${new Date().toISOString()}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error exporting data to CSV:", error);
    }
  };

  const getCSV = async () => {
    try {
      const response = await fetch(`${BASE_URL}${QUOTES}?export=1`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const data = await response.json();

      return data?.result.quotes;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCSV();
  }, []);
  const {
    data: quotesData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["quotes", page, order, orderSense, limitrows], // Añade limitrows a las dependencias
    queryFn: () => getQuotes({ page: page + 1, limit: limitrows }), // Usa el valor actualizado de limitrows
  });

  const handleOrderChange = (newOrder: string) => {
    if (order === newOrder) {
      setOrderSense((prev) => (prev === "ASC" ? "DESC" : "ASC"));
    } else {
      setOrder(newOrder);
      setOrderSense("ASC");
    }
    setSelectedColumn(newOrder); // Actualiza la columna seleccionada
    setPage(0);
    refetch();
  };

  const buttonStyle = (column: string) => ({
    color: selectedColumn === column ? "red" : "black",
    borderRadius: "12px", // Ajuste adicional para la forma del botón
    padding: 2,
    marginLeft: "10px",
  });

  // Modificar las funciones de orden para que llamen a handleOrderChange
  const dateOrder = () => handleOrderChange("date_created");
  const city_townOrder = () => handleOrderChange("city_town");
  const state_region_provinceOrder = () =>
    handleOrderChange("state_region_province");
  const zip_codeOrder = () => handleOrderChange("zip_code");
  const date_mail_sentOrder = () => handleOrderChange("date_mail_sent");
  const country_codeOrder = () => handleOrderChange("country_code");
  const scoreOrder = () => handleOrderChange("score");

  // Uso de useEffect para refetch cuando order o orderSense cambian
  useEffect(() => {
    refetch();
  }, [order, orderSense, refetch]);

  useEffect(() => {
    refetch(); // Refetch los datos cuando limitrows cambie
  }, [limitrows, refetch]);
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // Update filters whenever date values change
  const handleDateChange = (
    field: keyof Filters,
    newValue: dayjs.Dayjs | null
  ) => {
    const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : null;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: formattedDate,
    }));
  };
  const applyFilters = (data: QuoteTypes[]) => {
    const filtered = data.filter((quote) => {
      const quoteDate = new Date(quote.date_created).getTime();

      const fromDate = filters.date_from
        ? new Date(filters.date_from).getTime()
        : null;
      const toDate = filters.date_to
        ? new Date(filters.date_to).getTime()
        : null;

      return (
        Object.keys(filters).every((key) => {
          if (!filters[key as keyof typeof filters]) return true;
          if (key === "address") {
            return (
              quote.address
                ?.toLowerCase()
                .includes(filters[key].toLowerCase()) ||
              quote.address_line2
                ?.toLowerCase()
                .includes(filters[key].toLowerCase())
            );
          }
          if (key === "name") {
            return (
              quote.first_name
                ?.toLowerCase()
                .includes(filters[key].toLowerCase()) ||
              quote.last_name
                ?.toLowerCase()
                .includes(filters[key].toLowerCase())
            );
          }
          if (key === "date_from" || key === "date_to") {
            return true; // Se maneja aparte
          }
          return quote[key as keyof QuoteTypes]
            ?.toString()
            .toLowerCase()
            .includes(filters[key as keyof typeof filters].toLowerCase());
        }) &&
        (fromDate === null || quoteDate >= fromDate) &&
        (toDate === null || quoteDate <= toDate)
      );
    });

    return filtered.sort((a, b) => {
      const dateA = new Date(a.date_created).getTime();
      const dateB = new Date(b.date_created).getTime();
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  const filteredQuotes = applyFilters(quotesData || []);

  const handleSelectionQuote = (quote: QuoteTypes) => {
    setQuoteId(quote.id);
    setSelectedQuote(quote);
    setShowQuoteFormDetails(true);
  };

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  const handleSelectionFormDetails = (quote: QuoteTypes) => {
    modalFormDetails.onOpen("modalFormDetails", quote);
  };

  const clickNext = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      if (nextPage < totalPages) {
        refetch();
        return nextPage;
      }
      return prevPage; // Do not change page if already at the last one
    });
  };

  const clickPrevious = () => {
    setPage((prevPage) => {
      const prev = Math.max(prevPage - 1, 0);
      refetch();
      return prev;
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (reloadState === true) {
        refetch();
        setReloadState(false);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [reloadState, refetch, setReloadState]);

  return (
    <Main>
      <div className="flex flex-col w-full px-20 py-8">
        <div className="flex justify-between items-center">
          <div className=" flex gap-3 items-center">
            <h1 className="text-xl font-semibold py-4">
              Quotes {totalQuotes}{" "}
            </h1>
            <button
              onClick={() => getQuotes({ page: page + 1, limit: limitrows })}
              className=" w-8 h-8 bg-white rounded-full flex justify-center items-center text-black shadow-lg hover:bg-slate-100"
            >
              <IoReload />
            </button>
          </div>
          <button
            className=" text-black bg-white flex items-center space-x-2 hover:bg-gray-600 hover:text-white transition   p-2 rounded-md"
            onClick={exportQuotesToCSV}
          >
            <p>Export as CSV</p>
            <CiExport className="h-5 w-5" />
          </button>
        </div>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-white" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ bgcolor: "black", color: "white" }}
          >
            <Typography>Filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} mb={2}>
              {/* Existing filters */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Code"
                  variant="outlined"
                  size="small"
                  name="id"
                  value={filters.id}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Price"
                  variant="outlined"
                  size="small"
                  name="score"
                  value={filters.score}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Email"
                  variant="outlined"
                  size="small"
                  name="email"
                  value={filters.email}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Name"
                  variant="outlined"
                  size="small"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Phone number"
                  variant="outlined"
                  size="small"
                  name="phone_number"
                  value={filters.phone_number}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Mail sent"
                  variant="outlined"
                  size="small"
                  name="date_mail_sent"
                  value={filters.date_mail_sent}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>

              {/* New filters for date range */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={12} sm={6} md={4}>
                  <DateField
                    label="Date From"
                    variant="outlined"
                    size="small"
                    name="date_form"
                    format="YYYY-MM-DD"
                    value={valueFrom}
                    onChange={(newValue) => {
                      setValueFrom(newValue);
                      handleDateChange("date_from", newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DateField
                    label="Date To"
                    variant="outlined"
                    size="small"
                    name="date_to"
                    format="YYYY-MM-DD"
                    value={valueTo}
                    onChange={(newValue) => {
                      setValueTo(newValue);
                      handleDateChange("date_to", newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className="flex gap-2 items-center">
                    <p>Show expired</p>
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                      color="error"
                    />
                  </div>
                </Grid>
                <div className="px-4">
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={resetFilters}
                    sx={{ mt: 2 }}
                  >
                    Reset Filters
                  </Button>
                </div>
              </LocalizationProvider>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <div className="overflow-x-auto ">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className=" bg-gray-100">
                  <TableCell aria-label="Quote-id">Code</TableCell>
                  <TableCell aria-label="Date">
                    <div className="flex items-center">
                      Date{" "}
                      <button
                        onClick={dateOrder}
                        style={buttonStyle("date_created")}
                      >
                        <MdOutlineSwapVert size={20} />
                      </button>
                    </div>
                  </TableCell>
                  <TableCell aria-label="Name">Name </TableCell>
                  <TableCell>
                    <div className="flex items-center  ">Due date </div>
                  </TableCell>
                  <TableCell aria-label="Email">Product</TableCell>
                  <TableCell aria-label="Vehicle">Vehicle</TableCell>
                  <TableCell aria-label="Branch">Branch</TableCell>

                  <TableCell aria-label="Price">
                    <div className="flex items-center  ">
                      Price{" "}
                      <button onClick={scoreOrder} style={buttonStyle("score")}>
                        <MdOutlineSwapVert size={20} />
                      </button>
                    </div>
                  </TableCell>
                  <TableCell aria-label="Mail status">Mail Status</TableCell>
                  <TableCell aria-label="Details">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <div>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={11}>Loading...</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  filteredQuotes.map((quote: QuoteTypes) => (
                    <QuoteTableRow
                      key={quote.id} // Add key prop here
                      quote={quote}
                      handleSelectionQuote={handleSelectionQuote}
                      handleSelectionFormDetails={handleSelectionFormDetails}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <div className="flex justify-between items-center pt-4 ">
              <div className="flex gap-2 ">
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ minWidth: 120 }}
                >
                  <InputLabel id="demo-simple-select-label">Rows</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={limitrows} // El valor del Select debería ser el estado limitrows
                    onChange={handleChange2} // Actualiza el estado al cambiar el valor
                    label="Limit"
                  >
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex justify-between items-cente  p-4 gap-10">
                <button
                  onClick={clickPrevious}
                  disabled={page === 0}
                  className={`px-4 py-2 rounded-md w-24 text-white bg-black ${
                    page === 0 && "bg-gray-300 text-black"
                  }`}
                >
                  Previous
                </button>

                <div className="flex items-center text-black">
                  <Typography variant="body1" className="mx-2">
                    Page {page + 1} of {totalPages}
                  </Typography>
                </div>
                {page + 1 < totalPages && (
                  <button
                    onClick={clickNext}
                    className=" bg-black px-4 py-2 rounded-md w-24 text-white "
                  >
                    Next
                  </button>
                )}
              </div>
              <div className="flex gap-2 ">
                <p className=" text-white">v.1</p>
              </div>
            </div>
          </div>

          {showQuoteFormDetails && (
            <ModalRowDetailsForm
              setShowQuoteFormDetails={setShowQuoteFormDetails}
              showQuoteFormDetails={showQuoteFormDetails}
              selectedQuote={selectedQuote}
            />
          )}
          {modalFormDetails.isOpen && <ModalRowFormDetails />}
        </div>
      </div>
    </Main>
  );
};

export default Quotes;
