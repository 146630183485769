import { create } from "zustand";

const tokenKey = "user_info_admin_piranha"


interface UserInfoStoreProps {
    userInfo: any
    setUserInfo: (userInfo: any) => void
}

const useUserInfo = create<UserInfoStoreProps>((set) => ({
    userInfo: localStorage.getItem(tokenKey) ?? "",
    setUserInfo: async (userInfo) => {
        try {
            await localStorage.setItem(tokenKey, userInfo)
        } catch (error) {
            console.log(error)
        }

        set({ userInfo })
    }
}))

export { useUserInfo }