import React from "react";
import "./index.css";

import Router from "./router/Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();

const AppContainer = () => {
  return <Router />;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="top-center" />
      <AppContainer />
    </QueryClientProvider>
  );
}

export default App;
