import React from "react";
import { Button } from "../ui/Button";
import { BASE_URL } from "../../config/api.config";
import { useTokenStore } from "../../hooks/useTokenStore";

interface ModalDeleteConfirmationProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  optionToDelete: any;
  refreshOptions: () => void;
}

const ModalDeleteConfirmation: React.FC<ModalDeleteConfirmationProps> = ({
  showModal,
  setShowModal,
  optionToDelete,
  refreshOptions,
}) => {
  const token = useTokenStore((s) => s.token);

  const handleDeleteOption = async () => {
    await fetch(`${BASE_URL}canopy.trays?id=${optionToDelete.id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Option deleted:", data);
        setShowModal(false);
        refreshOptions();
      })
      .catch((err) => {
        console.error("Error deleting option:", err);
      });
  };

  return (
    showModal && (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 w-96">
          <h2 className="text-xl font-bold mb-4">Are you sure?</h2>
          <p className="mb-4">
            Do you really want to delete this option? This action cannot be
            undone.
          </p>
          <div className="flex justify-end gap-2">
            <Button
              onClick={() => setShowModal(false)}
              type="button"
              className="bg-gray-500 hover:bg-gray-600 text-white"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteOption}
              type="button"
              className="bg-red-500 hover:bg-red-600 text-white"
            >
              Yes, Delete
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalDeleteConfirmation;
