import { create } from "zustand";

export type ModalType = "modalOptions";

type Option = {
  id: string;
  option_text: string;
  img_path: string | null;
};

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  options: Option[];
  onOpen: (type: ModalType, options: Option[]) => void;
  onClose: () => void;
}

export const useModalOptions = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  options: [],
  onOpen: (type, options) => set({ isOpen: true, type, options }),
  onClose: () => set({ type: null, isOpen: false }),
}));
