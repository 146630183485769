import Layers from "../assets/icons/icons8-capas-90.png"
import ChevronDown from "../assets/icons/icons8-chevron-abajo-90.png"
import SelectorImage from "../assets/icons/icons8-montaña-96.png"
import Message from "../assets/icons/icons8-cita-derecha-96.png"
import Address from "../assets/icons/icons8-dirección-96.png"
import LayersAnimated from "../assets/icons/icons8-animado-96.png"

export const formsTypes = [
    {
        id: "",
        question: "Instant Tray & \nCanopy Quote Form",
        type: "entry",
        number_form: 0,
        img: Layers
    },
    {
        id: "",
        question: "Please tell us a bit \nabout yourself",
        type: "contact_information",
        number_form: 1,
        img: Layers

    },
    {
        id: "q0",
        question: "Select your closest\nbranch",
        type: "multiple_selection",
        number_form: 2,
        img: Layers

    },
    {
        id: "q1",
        question: "Select your vehicle",
        type: "selector",
        number_form: 3,
        img: ChevronDown

    },
    {
        id: "q2",
        question: "And now the Year",
        type: "selector",
        number_form: 4,
        img: ChevronDown

    },
    {
        id: "q6",
        question: "Which of the \nfollowing you want \nto be quoted on?",
        type: "selector_with_images",
        number_form: 6,
        img: SelectorImage

    },
    {
        id: "s2",
        question: "We only have Trays",
        type: "message",
        number_form: 12,
        img: Message
    },
    {
        id: "q21",
        question: "(Video Question)",
        type: "address",
        number_form: 21,
        img: Address

    }

];

export const formFinalTypes = [
    {
        id: "b",
        question: "We will get back to you with a quote for freight. \n\n\n Your Quote Total without Freight",
        type: "final",
        number_form: 1,
        img: LayersAnimated,
        optional: null
    },
    {
        id: "c",
        question: "Your Pick Up Location: \n\n\n 321 Greens Rd Keysborough VIC 3173 \n\n\n Phone 03 9762 1200",
        type: "final",
        number_form: 2,
        img: LayersAnimated,
        optional: null
    },
    {
        id: "d",
        question: "Your Pick Up Location: \n\n\n 4/919-925 Nudgee Rd Banyo QLD 4014 \n\n\n Phone 03 9762 1200",
        type: "final",
        number_form: 3,
        img: LayersAnimated,
        optional: null
    },
    {
        id: "e",
        question: "Your Pick Up Location: \n 2/314 Hoxton Park Rd Prestons NSW 2170 \n Phone 03 9762 1200",
        type: "final",
        number_form: 4,
        img: LayersAnimated,
        optional: null
    },
    {
        id: "f",
        question: "Your Pick Up Location: \n 3 Kalli St Malaga WA 6090\n Phone 02 9609 5122",
        type: "final",
        number_form: 5,
        img: LayersAnimated, optional: null
    },
    {
        id: "g",
        question: "Your Pick Up Location: \n 3 3/110 Fitzgerald Rd Laverton North VIC 3026 \n Phone 03 9369 8113",
        type: "final",
        number_form: 6,
        img: LayersAnimated,
        optional: null
    },
    {
        id: "h",
        question: "We are not sure where you are heading to, but...... You are bogged\n!Grab the jack, activate the winch and try again.",
        type: "final",
        number_form: 7,
        img: LayersAnimated,
        optional: "Unfortunately the selected items and your vehicle combination are invalid. this could be an error on our end, in the meantime feel free to contact us on: 1800 022 666 or via the chat bot in the bottom left or via the contact us page if you want to proceed further"
    },
]