import React, { useState } from "react";
import background from "../img/large.jpg";
import logopiranha from "../assets/Piranha logo.avif";
import { useAtom } from "jotai";
import { userId } from "../atoms";
import { useNavigate } from "react-router-dom";
import { BASE_URL, LOGIN } from "../config/api.config";
import { useTokenStore } from "../hooks/useTokenStore";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { cn } from "../lib/utils";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/Form";
import toast from "react-hot-toast";
import { useUserInfo } from "../hooks/useUserInfo";

const loginUsername = z.object({
  username: z.string().min(1, "Username must not be empty"),
});

const loginPassword = z.object({
  password: z.string().min(1, "Password must not be empty"),
});

type FormUsernameSchema = z.infer<typeof loginUsername>;
type FormPasswordSchema = z.infer<typeof loginPassword>;

export const Login = () => {
  const navigate = useNavigate();

  const [showUserNameLogin, setShowUserNameLogin] = useState(false);
  const [id, setId] = useAtom(userId);
  const [showPassword, setShowPassword] = useState(false);
  const [continueForm, setContinueForm] = useState(false);
  const setUserInfo = useUserInfo((s) => s.setUserInfo);

  const setTokenStore = useTokenStore((s) => s.setToken);
  const token = useTokenStore((s) => s.token);

  const usernameForm = useForm<FormUsernameSchema>({
    resolver: zodResolver(loginUsername),
    defaultValues: {
      username: "",
    },
  });

  const passwordForm = useForm<FormPasswordSchema>({
    resolver: zodResolver(loginPassword),
    defaultValues: {
      password: "",
    },
  });

  const handleShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const loginWithUsername = async (username: string) => {
    const userEmailData = new FormData();
    userEmailData.append("mode", "username");
    userEmailData.append("username", username);
    console.log("token user: ", token);

    const data = await fetch(`${BASE_URL}${LOGIN}`, {
      method: "POST",
      headers: {
        "Accept-Language": "en-US,en;q=0.8",
      },
      body: userEmailData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setUserInfo(username);
          setTokenStore(data.result.token);
          setId(data.result.id);
          setShowUserNameLogin(true);
          setContinueForm(true);
          localStorage.setItem("userData", JSON.stringify(data.result));
          // loginWithPassword(data.result.token, data.result.id); // Trigger login with password
        } else {
          toast.error(`${data.result.error_msg}`);
          console.log("error email");
        }
      })
      .catch((err) => console.log(err));
    return data;
  };

  const loginWithPassword = async (password: string) => {
    const userPassData = new FormData();
    userPassData.append("mode", "password");
    userPassData.append("id", id);
    userPassData.append("password", password);
    console.log("token pass: ", token);
    const data = await fetch(`${BASE_URL}${LOGIN}`, {
      method: "POST",
      headers: {
        //Token: token,
        Authorization: "Bearer " + token,
        "Accept-Language": "en-US,en;q=0.8",
      },
      body: userPassData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          console.log(data);
          setTokenStore(data.result.token);
          navigate("/");
        } else {
          toast.error(`${data.result.error_msg}`);
        }
      })
      .catch((err) => console.log(err));

    return data;
  };

  const onSubmitUsername = async (values: FormUsernameSchema) => {
    loginWithUsername(values.username);
  };

  const onSubmitPassword = async (values: FormPasswordSchema) => {
    loginWithPassword(values.password);
  };

  return (
    <div
      className="flex justify-center items-center"
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      ></div>
      <div
        className="bg-black/90 w-[400px] max-h-[500px] rounded-2xl p-10 flex flex-col gap-4"
        style={{ opacity: 0.9 }}
      >
        <div className="flex justify-center">
          <img src={logopiranha} alt="" />{" "}
        </div>

        {!continueForm && (
          <Form {...usernameForm}>
            <form
              onSubmit={usernameForm.handleSubmit(onSubmitUsername)}
              className="flex flex-col space-y-6"
            >
              <div className="relative flex flex-col w-full">
                <FormField
                  control={usernameForm.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem className="flex flex-col items-start w-full">
                      <FormLabel className="text-white">Username</FormLabel>
                      <FormControl>
                        <input
                          {...field}
                          className="h-10 p-4 border border-red-600 rounded-lg w-full"
                        />
                      </FormControl>
                      <FormMessage className="text-gray-200" />
                    </FormItem>
                  )}
                />
              </div>
              <button
                className={cn(
                  "rounded-lg bg-red-600 h-10 text-white w-full",
                  usernameForm.getValues("username") === "" && "bg-red-600/40"
                )}
              >
                Continue
              </button>
            </form>
          </Form>
        )}

        {continueForm && (
          <Form {...passwordForm}>
            <form
              onSubmit={passwordForm.handleSubmit(onSubmitPassword)}
              className="flex flex-col space-y-6"
            >
              <div className="relative flex flex-col w-full">
                <FormField
                  control={passwordForm.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="flex flex-col items-start">
                      <FormLabel className="text-white">Password</FormLabel>
                      <FormControl>
                        <input
                          {...field}
                          type={showPassword ? "text" : "password"}
                          className="h-10 p-4 border border-red-600 rounded-lg w-full"
                        />
                      </FormControl>
                      <FormMessage className="text-gray-200" />
                    </FormItem>
                  )}
                />
                <div
                  className="absolute top-9 right-5 cursor-pointer"
                  onClick={() => handleShowPassword()}
                >
                  {showPassword ? (
                    <FaEye className="h-5 w-5 text-gray-500" />
                  ) : (
                    <FaEyeSlash className="h-5 w-5 text-gray-500" />
                  )}
                </div>
              </div>
              <button
                className={cn(
                  "rounded-lg bg-red-600 h-10 text-white w-full",
                  passwordForm.getValues("password") === "" && "bg-red-600/40"
                )}
              >
                Sign in
              </button>
            </form>
          </Form>
        )}
      </div>
    </div>
  );
};
