import { create } from "zustand";

const tokenKey = "token_form_pirahna"


interface TokenStoreProps {
    token: string;
    setToken: (token: string) => void
}

const useTokenStore = create<TokenStoreProps>((set) => ({
    token: localStorage.getItem(tokenKey) ?? "",
    setToken: async (token) => {
        try {
            await localStorage.setItem(tokenKey, token)
        } catch (error) {
            console.log(error)
        }

        set({ token })
    }
}))

export { useTokenStore }