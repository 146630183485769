import { create } from "zustand";
import { QuoteTypes } from "../types";

export type ModalType = "modalFormDetails";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  quote: QuoteTypes | null;
  onOpen: (type: ModalType, quote: QuoteTypes) => void;
  onClose: () => void;
}

export const useModalFormDetails = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  quote: null,
  onOpen: (type, quote) => set({ isOpen: true, type, quote }),
  onClose: () => set({ type: null, isOpen: false }),
}));
