import { create } from "zustand";

export type ModalType = "modalBulkCsv";

type Option = "vehicle" | "year" | "branch" | "product" | null

interface ModalStore {
    type: ModalType | null;
    isOpen: boolean;
    option: Option
    onOpen: (type: ModalType, option: Option) => void;
    onClose: () => void;
}

export const useModalBulkCSV = create<ModalStore>((set) => ({
    type: null,
    isOpen: false,
    option: null,
    onOpen: (type, option) => set({ isOpen: true, type, option }),
    onClose: () => set({ type: null, isOpen: false }),
}));
