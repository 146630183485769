import React, { useState } from "react";
import { Main } from "../Main/Main";
import CardManagementVehicles from "./CardManagentVehicles";
import { OptionsTypes } from "../../types";
import ModalManagement from "./ModalManagement";
import CardManagementYear from "./CardManagementYear";
import CardManagementBranches from "./CardManagementBranches";
import CardManagementQuoteOptions from "./CardManagementQuoteOptions";

export type ShowItem = {
  edit: number;
  trash: number;
  where: string;
};

export type ShowModal = {
  edit: boolean;
  delete?: boolean;
  section: string;
};

const ManagementGeneral = () => {
  const [showItem, setShowItem] = useState<ShowItem>({
    edit: -1,
    trash: -1,
    where: "",
  });
  const [showModal, setShowModal] = useState<ShowModal>({
    edit: false,
    delete: false,
    section: "",
  });
  const [selectedVehicle, setSelectedVehicle] = useState<OptionsTypes>({
    name: "",
    id: "",
    img_path: null,
    option_text: "",
  });
  const [selectedYear, setSelectedYear] = useState<OptionsTypes>({
    name: "",
    id: "",
    img_path: null,
    option_text: "",
  });
  const [selectedBranch, setSelectedBranch] = useState<OptionsTypes>({
    name: "",
    id: "",
    img_path: null,
    option_text: "",
  });
  const [selectedQuotedOption, setSelectedQuotedOption] =
    useState<OptionsTypes>({
      name: "",
      id: "",
      img_path: null,
      option_text: "",
    });

  const editVehicle = (id: string, name: string) => {
    console.log(`vehicle ${name} with ${id} edited`);
  };

  const deleteVehicle = (id: string) => {
    console.log(`vehicle deleted with id:${id}`);
  };
  const editYear = (id: string, name: string) => {
    console.log(`Year ${name} with ${id} edited`);
  };

  const deleteYear = (id: string) => {
    console.log(`Year deleted with id:${id}`);
  };

  const editBranch = (id: string, name: string) => {
    console.log(`Branch ${name} with ${id} edited`);
  };
  const deleteBranch = (id: string, name: string) => {
    console.log(`Branch deleted with id:${id}`);
  };
  const editQuotedOption = (id: string, name: string) => {
    console.log(`Quoted Option ${name} with ${id} edited`);
  };
  const deleteQuotedOption = (id: string, name: string) => {
    console.log(`Quoted Option deleted with id:${id}`);
  };
  return (
    <Main>
      <div className="flex flex-col w-full laptop:pb-20 p-4">
        <h1 className="text-xl font-semibold py-4">Management</h1>
        <div className="mt-12 flex flex-col laptop:flex-row laptop:gap-12 space-y-5 laptop:space-y-0">
          <CardManagementVehicles
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            selectedVehicle={selectedVehicle}
            setSelectedVehicle={setSelectedVehicle}
          />
          <CardManagementYear
            setSelectedYear={setSelectedYear}
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            selectedYear={selectedVehicle}
          />
        </div>
        <div className="mt-12 flex flex-col laptop:flex-row laptop:gap-12 space-y-5 laptop:space-y-0">
          <CardManagementBranches
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
          />
          <CardManagementQuoteOptions
            setSelectedQuoteOption={setSelectedQuotedOption}
            setShowItem={setShowItem}
            showItem={showItem}
            setShowModal={setShowModal}
            selectedQuoteOption={selectedQuotedOption}
          />
        </div>
        {showModal.edit && showModal.section === "vehicle" && (
          <ModalManagement
            type="edit"
            handleAction={editVehicle}
            setShowModal={setShowModal}
            section="vehicle"
            selectedVehicle={selectedVehicle}
            showModal={showModal}
          />
        )}
        {showModal.delete && showModal.section === "vehicle" && (
          <ModalManagement
            type="delete"
            handleAction={deleteVehicle}
            setShowModal={setShowModal}
            section="vehicle"
            selectedVehicle={selectedVehicle}
            showModal={showModal}
          />
        )}
        {showModal.edit && showModal.section === "year" && (
          <ModalManagement
            type="edit"
            handleAction={editYear}
            setShowModal={setShowModal}
            section="year"
            selectedVehicle={selectedYear}
            showModal={showModal}
          />
        )}
        {showModal.delete && showModal.section === "year" && (
          <ModalManagement
            type="delete"
            handleAction={deleteYear}
            setShowModal={setShowModal}
            section="year"
            selectedVehicle={selectedYear}
            showModal={showModal}
          />
        )}
        {showModal.edit && showModal.section === "branch" && (
          <ModalManagement
            type="edit"
            handleAction={editBranch}
            setShowModal={setShowModal}
            section="branch"
            selectedVehicle={selectedBranch}
            showModal={showModal}
          />
        )}
        {showModal.delete && showModal.section === "branch" && (
          <ModalManagement
            type="delete"
            handleAction={deleteBranch}
            setShowModal={setShowModal}
            section="branch"
            selectedVehicle={selectedBranch}
            showModal={showModal}
          />
        )}
        {showModal.edit && showModal.section === "quoted-options" && (
          <ModalManagement
            type="edit"
            handleAction={editQuotedOption}
            setShowModal={setShowModal}
            section="quoted-options"
            selectedVehicle={selectedQuotedOption}
            showModal={showModal}
          />
        )}
        {showModal.delete && showModal.section === "quoted-options" && (
          <ModalManagement
            type="delete"
            handleAction={deleteQuotedOption}
            setShowModal={setShowModal}
            section="quoted-options"
            selectedVehicle={selectedQuotedOption}
            showModal={showModal}
          />
        )}
      </div>
    </Main>
  );
};

export default ManagementGeneral;
