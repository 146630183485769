/* eslint-disable @typescript-eslint/no-inferrable-types */

const DEVELOPMENT_MODE: number = 1; // Ensure DEVELOPMENT_MODE is explicitly typed

// Default values for URL and BASE_URL
export const URL: string = "http://192.168.1.46/";
export let BASE_URL: string = URL;
export let BASE_URL_IMG: string = "";

// Set BASE_URL based on DEVELOPMENT_MODE
if (DEVELOPMENT_MODE === 2) {
    // Desarrollo (Development)
    BASE_URL = "http://192.168.1.46/labtest/elite-api-autoquote/v1/";
    BASE_URL_IMG = "http://192.168.1.46/img.piranhaoffroad.es/";
    
} else if (DEVELOPMENT_MODE === 1) {
    // Producción (Production)
    BASE_URL = "https://api.piranhaoffroad.es/";
    BASE_URL_IMG = "https://img.piranhaoffroad.es/";
    
}

// Export constants
export const LOGIN: string = "login";
export const FORM: string = "form";
export const QUOTES: string = "quotes";
export const OFFICES: string = "branch.offices";
export const SERVICES: string = "adm.sys.services";
