import { TableCell, TableRow } from "@mui/material";
import { QuoteTypes } from "../../types";
import React, { useState } from "react";
import axios from "axios";
import { FaEye, FaUser } from "react-icons/fa";
import { isAfter, parseISO } from "date-fns";
import CheckIcon from "@mui/icons-material/Check";
import { CiViewList } from "react-icons/ci";
import formatDateTime from "../../hooks/dateTime";
import { BASE_URL } from "src/config/api.config";
import { useTokenStore } from "src/hooks/useTokenStore";

interface QuoteTableRowProps {
  quote: QuoteTypes;
  handleSelectionQuote: (quote: QuoteTypes) => void;
  handleSelectionFormDetails: (quote: QuoteTypes) => void;
}

const QuoteTableRow = ({
  quote,
  handleSelectionQuote,
  handleSelectionFormDetails,
}: QuoteTableRowProps) => {
  const [showImage, setShowImage] = useState(true);
  const [updated, setUpdated] = useState(false); // Nuevo estado para rastrear la actualización

  const token = useTokenStore((s) => s.token);

  const dueDate = quote.due_date ? parseISO(quote.due_date) : null;
  const isExpired = dueDate ? isAfter(new Date(), dueDate) : false;

  // Estilos condicionales basados en el estado de actualización
  const rowStyles =
    updated
      ? {
          backgroundColor: "#f0f0f0",
          color: "#b1aeaf",
        }
      : quote.status_id === "2"
      ? {
          backgroundColor: "#f0f0f0",
          color: "#b1aeaf",
        }
      : {};

  const handleClick = async () => {
    if (quote.status_id === "1") {
      try {
        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("quotes_id_list", quote.id.toString());
        formData.append("status_id", "2");

        const response = await axios.post(`${BASE_URL}quotes.status`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Response:", response.data);
        // Cambiar el estado para reflejar la actualización
        setUpdated(true);
      } catch (error) {
        console.error("Error:", error);
        // Maneja el error, muestra un mensaje, etc.
      }
    }
  };

  return (
    <TableRow
    className="bg-white"
      key={quote.id}
      sx={{
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          cursor: "pointer",
        },
        ...rowStyles, // Aplicar estilos condicionales
      }}
      onClick={handleClick} // Manejar el click en la fila
    >
      <TableCell>{quote.id}</TableCell>

      <TableCell>
        {formatDateTime(new Date(quote.date_created.split(" ").join("T")))}
      </TableCell>
      <TableCell>
        <div className="flex items-center space-x-3">
          <div className="h-6 w-6 flex-col rounded-full items-center justify-center hover:bg-gray-100 flex">
            <FaUser />
          </div>
          <p>
            {quote.first_name} {quote.last_name}
          </p>
        </div>
      </TableCell>
      <TableCell>{quote.due_date}</TableCell>
      <TableCell
        style={{
          maxWidth: "100px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        title={quote.main_part_no || "undefined"}
      >
        {quote.main_part_no}
      </TableCell>
      <TableCell>
        {quote.vehicle} {quote.year}
      </TableCell>
      <TableCell>{quote.branch}</TableCell>
      <TableCell>${quote.score}</TableCell>
      <TableCell>
        {isExpired ? (
          <p className="text-gray-500 font-bold">Expired</p>
        ) : quote.date_mail_sent === "0000-00-00 00:00:00" ||
          quote.date_mail_sent === null ? (
          <p className="text-red-500 font-bold">Error</p>
        ) : (
          <CheckIcon color="success" />
        )}
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <CiViewList
            onClick={() => handleSelectionQuote(quote)}
            className="hover:text-blue-500 h-5 w-5"
          />
          <FaEye
            onClick={() => handleSelectionFormDetails(quote)}
            className="hover:text-blue-500 h-5 w-5"
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default QuoteTableRow;
