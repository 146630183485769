import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { cn } from "../../lib/utils";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import { VscLayers } from "react-icons/vsc";

export type Form = {
  id: string;
  type: string;
  question: string;
  number_form: number;
};

interface MetricsFinalFormCardProps {
  form: Form;
  setSelectForm: Dispatch<SetStateAction<Form>>;
  selectForm: Form;
}

const MetricsFinalFormCard = ({
  form,
  selectForm,
  setSelectForm,
}: MetricsFinalFormCardProps) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ref = useRef<any>();

  useEffect(() => {
    // Function to handle the click event
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      key={form.id}
      onClick={() => setSelectForm(form)}
      className={cn(
        "py-2 flex items-start space-x-2 relative p-2 w-60  my-1 cursor-pointer hover:bg-gray-200 rounded-lg transition mx-1 group",
        form.number_form === selectForm?.number_form && "bg-gray-200"
      )}
    >
      <div
        className={cn(
          "w-[45px] h-7 flex justify-between border items-center space-x-2 px-1 rounded-lg",
          form.type === "final" && "bg-gray-200"
        )}
      >
        <div className="w-6">
          <VscLayers className="h-4 w-4 rotate-90" />
        </div>

        <p className="text-[12px] font-semibold uppercase">{form.id}</p>
      </div>
      <h3 className="text-[13px] text-gray-500 w-36 select-none  font-normal text-ellipsis line-clamp-1">
        {form.question}
      </h3>
      <div
        className={cn(
          "hidden group-hover:inline-flex mt-1 hover:bg-gray-300 h-6 w-6 transition rounded-lg items-center justify-center ",
          showDropDown &&
            "inline-flex h-6 w-6 transition rounded-lg items-center justify-center"
        )}
      >
        <BsThreeDotsVertical
          onClick={() => setShowDropDown(true)}
          className="h-4 w-4 justify-center"
        />
      </div>

      <div
        ref={ref}
        className={cn(
          " w-32   bg-white absolute top-0 transition rounded-xl p-1 -right-32 z-20 shadow border-4 border-gray-200 ",
          showDropDown ? "block" : "hidden"
        )}
      >
        <div className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-gray-200 transition">
          <BiTrash className="h-5 w-5" />
          <p className="text-sm">Remove</p>
        </div>
      </div>
    </div>
  );
};

export default MetricsFinalFormCard;
