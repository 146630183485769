import React, { useEffect, useState } from "react";
import { Main } from "../components/Main/Main";
import { BASE_URL, OFFICES } from "../config/api.config";
import { useTokenStore } from "../hooks/useTokenStore";
import { IoAddCircleSharp, IoTrashOutline } from "react-icons/io5";
import ConfirmDeleteModal from "../components/Modals/ConfirmDeleteModal"; // Asegúrate de importar el modal

export const Offices = () => {
  const token = useTokenStore((s) => s.token);
  const [offices, setOffices] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedOfficeId, setSelectedOfficeId] = useState<number | null>(null);
  const [newOffice, setNewOffice] = useState({
    id: "",
    name: "",
    email: "",
    address: "",
    mail_optional: "",
    days_quote_valid: 30,
    url_firment_guidelines:
      "https://www.piranhaoffroad.com.au/pages/fitment-guide",
    phone: "",
  });

  // Estado para el modal de confirmación de eliminación
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [officeToDelete, setOfficeToDelete] = useState<number | null>(null);

  // Estado para mostrar errores
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    address: "",
  });

  const getOffices = async () => {
    try {
      const response = await fetch(`${BASE_URL}${OFFICES}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const data = await response.json();
      setOffices(data?.result?.branchOffices || []);
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewOffice({ ...newOffice, [name]: value });
  };

  const validateFields = () => {
    const { name, email, address } = newOffice;
    const newErrors = {
      name: "",
      email: "",
      address: "",
    };
    let isValid = true;

    if (!name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    if (!address.trim()) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    try {
      const formData = new FormData();
      formData.append("_method", isEditMode ? "PUT" : "POST");
      formData.append("id", newOffice.id);
      formData.append("name", newOffice.name);
      formData.append("email", newOffice.email);
      formData.append("address", newOffice.address);
      formData.append("mail_optional", newOffice.mail_optional);
      formData.append(
        "days_quote_valid",
        newOffice.days_quote_valid.toString()
      );
      formData.append(
        "url_firment_guidelines",
        newOffice.url_firment_guidelines
      );
      formData.append("phone", newOffice.phone);

      await fetch(`${BASE_URL}${OFFICES}`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });

      getOffices();
      setIsModalOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditClick = (office: any) => {
    setIsEditMode(true);
    setSelectedOfficeId(office.id);
    setNewOffice({
      id: office.id,
      name: office.name,
      email: office.mail,
      address: office.address,
      mail_optional: office.mail_optional || "",
      days_quote_valid: office.days_quote_valid || 30,
      url_firment_guidelines:
        office.url_firment_guidelines ||
        "https://www.piranhaoffroad.com.au/pages/fitment-guide",
      phone: office.phone,
    });
    setIsModalOpen(true);
  };

  const handleAddClick = () => {
    setIsEditMode(false);
    setSelectedOfficeId(null);
    setNewOffice({
      id: "",
      name: "",
      email: "",
      address: "",
      mail_optional: "",
      days_quote_valid: 30,
      url_firment_guidelines:
        "https://www.piranhaoffroad.com.au/pages/fitment-guide",
      phone: "",
    });
    setIsModalOpen(true);
  };

  const handleDeleteClick = (event: React.MouseEvent, officeId: number) => {
    event.stopPropagation();
    setOfficeToDelete(officeId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (officeToDelete !== null) {
      try {
        await fetch(`${BASE_URL}${OFFICES}?id=${officeToDelete}`, {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        getOffices();
        setIsDeleteModalOpen(false);
        setOfficeToDelete(null);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
    setOfficeToDelete(null);
  };

  useEffect(() => {
    getOffices();
  }, []);

  return (
    <Main>
      <div>
        <div className="pt-20 pl-20">
          <button
            className="p-2 bg-[#DC2626] hover:bg-red-700 rounded-xl text-white flex justify-center items-center gap-2"
            onClick={handleAddClick}
          >
            <IoAddCircleSharp size={20} />
            Add Office
          </button>
        </div>

        <div className="px-20 py-10 flex gap-10 flex-wrap">
          {offices.map((office) => (
            <div
              key={office.id}
              className="bg-white p-8 rounded-lg shadow-md border border-gray-300  mb-4 transition-transform transform hover:scale-105 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleEditClick(office)}
            >
              <h2 className="text-xl font-semibold mb-2">{office.name}</h2>
              <p className="text-gray-600 mb-2">
                <strong>Address:</strong> {office.address}
              </p>
              <p className="text-gray-600 mb-2">
                <strong>Phone:</strong> {office.phone}
              </p>
              <p className="text-gray-600 mb-2">
                <strong>Email:</strong> {office.mail}
              </p>
              {office.mail_optional && (
                <p className="text-gray-600 mb-2">
                  <strong>Optional Email:</strong> {office.mail_optional}
                </p>
              )}
              <p className="text-gray-600 mb-2">
                <strong>Days Quote Valid:</strong> {office.days_quote_valid}{" "}
                days
              </p>
              <a
                href={office.url_firment_guidelines}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Fitment Guidelines
              </a>
              <button
                className="mt-4 p-2 bg-red-500 hover:bg-red-700 text-white rounded-lg flex items-center justify-center"
                onClick={(e) => handleDeleteClick(e, office.id)}
              >
                <IoTrashOutline size={20} />
                <span className="ml-2">Delete</span>
              </button>
            </div>
          ))}
        </div>

        {isDeleteModalOpen && (
          <ConfirmDeleteModal
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}

        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-[400px]">
              <h2 className="text-xl font-semibold mb-4">
                {isEditMode ? "Edit Office" : "Add New Office"}
              </h2>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  value={newOffice.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 bg-white rounded-lg"
                  required
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={newOffice.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 bg-white rounded-lg"
                  required
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Address</label>
                <input
                  type="text"
                  name="address"
                  value={newOffice.address}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 bg-white rounded-lg"
                  required
                />
                {errors.address && (
                  <p className="text-red-500 text-sm">{errors.address}</p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={newOffice.phone}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 bg-white rounded-lg"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Optional Email
                </label>
                <input
                  type="email"
                  name="mail_optional"
                  value={newOffice.mail_optional}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 bg-white rounded-lg"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Days Quote Valid
                </label>
                <input
                  type="number"
                  name="days_quote_valid"
                  value={newOffice.days_quote_valid}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 bg-white rounded-lg"
                />
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 mb-2">
                  Fitment Guidelines URL
                </label>
                <input
                  type="text"
                  name="url_firment_guidelines"
                  value={newOffice.url_firment_guidelines}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border bg-white border-gray-300 rounded-lg"
                />
              </div>

              <div className="flex justify-end gap-4">
                <button
                  className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-lg"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg"
                  onClick={handleSubmit}
                >
                  {isEditMode ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Main>
  );
};
