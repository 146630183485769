import Modal from "react-modal";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormTypes, QuoteTypes } from "../../types";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL, QUOTES } from "../../config/api.config";
import { useTokenStore } from "../../hooks/useTokenStore";
import { IoChevronBackSharp, IoCheckmarkDoneOutline } from "react-icons/io5";
import FormDetailsQuestionsRow from "./FormDetailsQuestionsRow";
import { selectedQuoteId } from "../../atoms";
import { useAtom } from "jotai";
import Papa from "papaparse";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Function to export data to CSV
const exportToCSV = (quote: QuoteTypes, formDetails: FormTypes[]) => {
  const headers = [
    { label: "Quote ID", key: "id" },
    { label: "Address", key: "address" },
    { label: "Address Line 2", key: "address_line2" },
    { label: "City", key: "city_town" },
    { label: "Country Code", key: "country_code" },
    { label: "Email", key: "email" },
    { label: "First name", key: "first_name" },
    { label: "Last name", key: "last_name" },
    { label: "Phone number", key: "phone_number" },
    { label: "State", key: "state_region_province" },
    { label: "Mail Sent", key: "date_mail_sent" },
    { label: "ZipCode", key: "zip_code" },
    { label: "Price", key: "price" },
    { label: "Form Question", key: "form_question" },
    { label: "Form Response", key: "form_response" },
  ];

  const data = [
    {
      ...quote,
      form_question: formDetails.map((form) => form.text).join(", "),
      form_response: formDetails.map((form) => form.option_text).join(", "),
    },
  ];

  const csv = Papa.unparse(
    {
      fields: headers.map((header) => header.key),
      data: data,
    },
    {
      header: true,
    }
  );

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${quote.id}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

interface ModalRowDetailsFormProps {
  setShowQuoteFormDetails: Dispatch<SetStateAction<boolean>>;
  showQuoteFormDetails: boolean;
  selectedQuote: QuoteTypes;
}

const ModalRowDetailsForm = ({
  setShowQuoteFormDetails,
  showQuoteFormDetails,
  selectedQuote,
}: ModalRowDetailsFormProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [transition, setTransition] = useState(false);
  const token = useTokenStore((s) => s.token);
  const stylesModal: any = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.35)",
      zIndex: 200,
    },
    content: {
      position: "absolute",
      top: "-20px",
      left: "40px",
      right: "40px",
      bottom: "0px",
      overflow: "auto",
      border: "none",
      background: "#fff",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      padding: "20px",
      backgroundColor: "transparent",
    },
  };

  const [quoteId, setQuoteId] = useAtom(selectedQuoteId);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showQuoteFormDetails &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showQuoteFormDetails]);

  const closeModal = () => {
    setQuoteId("");
    setTransition(false);
    setTimeout(() => {
      setShowQuoteFormDetails(false);
    }, 150);
  };

  const getQuoteForms = async () => {
    const data = await fetch(`${BASE_URL}${QUOTES}?id=${quoteId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return data.result;
  };

  const { data: quotesDataForm, isLoading: isLoadingQuotesForm } = useQuery({
    queryKey: ["quote_form", selectedQuote.id],
    queryFn: getQuoteForms,
    enabled: showQuoteFormDetails,
  });

  useEffect(() => {
    if (showQuoteFormDetails) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    }
  }, [showQuoteFormDetails]);

  const handleExport = () => {
    if (selectedQuote && quotesDataForm?.form) {
      exportToCSV(selectedQuote, quotesDataForm.form);
    }
  };

  const handleSendAgain = () => {
    // Implement the logic to send the request again
    console.log("Send again triggered");
  };

  return (
    <Modal
      isOpen={showQuoteFormDetails}
      style={stylesModal}
      ariaHideApp={false}
    >
      <div
        className={`fixed z-[160] pb-10 ${
          transition === false ? "scale-x-0 translate-x-full" : "scale-x-100"
        } transition duration-200 w-[80%] laptop:w-[1000px] right-0 h-full bg-white rounded overflow-x-hidden`}
        ref={ref}
      >
        <div className="text-sm font-bold shadow bg-white px-8 py-5 flex justify-between ">
          <IoChevronBackSharp
            onClick={() => closeModal()}
            className="h-5 w-5 text-black cursor-pointer"
          />
        </div>
        {quotesDataForm?.form.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ bgcolor: "black", color: "white" }}
            >
              <Typography>Form Responses</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col py-4 border-t p-4 space-y-2">
                {quotesDataForm?.form.map((form: FormTypes) => (
                  <FormDetailsQuestionsRow key={form.id} form={form} />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-white" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ bgcolor: "black", color: "white" }}
          >
            <Typography>Mail status</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-col  p-4 space-y-2">
              {quotesDataForm?.quotesMailHistory.length === 0 ? (
                <div>
                  <p className="text-sm font-medium text-gray-500">No info</p>
                </div>
              ) : (
                <div className="flex flex-col  border-t p-4 space-y-2">
                  <div className="flex items-center gap-4">
                    {quotesDataForm?.quotesMailHistory?.[0].email}{" "}
                    <IoCheckmarkDoneOutline size={20} color="green" />
                  </div>
                  <div className="flex items-center gap-4">
                    <p>Error log: </p>
                    {quotesDataForm?.quotesMailHistory?.[0].error_log === "" ? (
                      <IoCheckmarkDoneOutline size={20} color="green" />
                    ) : (
                      quotesDataForm?.quotesMailHistory?.[0].error_log
                    )}
                  </div>
                  <div className="flex items-center gap-4">
                    <p>Sent status: </p>
                    {quotesDataForm?.quotesMailHistory?.[0].sent_result ===
                    "1" ? (
                      <IoCheckmarkDoneOutline size={20} color="green" />
                    ) : (
                      <>
                        <span>ERROR</span>
                        <button
                          onClick={handleSendAgain}
                          className="ml-2 bg-[#dc2626] text-white px-4 py-1 rounded"
                        >
                          Send Again
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="flex justify-end p-4">
          <button
            onClick={handleExport}
            className="bg-black text-white px-4 py-2 rounded"
          >
            Export to CSV
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRowDetailsForm;

