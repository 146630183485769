import React, { useEffect, useState } from "react";
import { IoIosSettings, IoIosStats } from "react-icons/io";
import { SideMenuBtnsStyles } from "./SideMenuBtns.styles";
import { NavLink } from "react-router-dom";
import { MdQueryStats } from "react-icons/md";
import { FaCar, FaWpforms, FaBell, FaServer } from "react-icons/fa";
import "./styles.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { BASE_URL, QUOTES } from "src/config/api.config";
import { useTokenStore } from "src/hooks/useTokenStore";
import { useAtom } from "jotai";
import { reload } from "../../atoms";

const configSide = [
  {
    title: "Statistics",
    redirectTo: "/",
    icon: <IoIosStats className="h-6 w-6" />,
    active: true,
  },
  {
    title: "Unread quotes",
    redirectTo: "/quotes",
    icon: <MdQueryStats className="h-6 w-6" />,
    active: true,
  },
  {
    title: "Forms",
    redirectTo: "/forms",
    icon: <FaWpforms className="h-6 w-6" />,
    active: false,
  },
  {
    title: "Management",
    redirectTo: "/management",
    icon: <FaCar className="h-6 w-6" />,
    active: true,
  },
  {
    title: "Settings",
    redirectTo: "/settings",
    icon: <IoIosSettings className="h-6 w-6" />,
    active: true,
  },
  {
    title: "Services",
    redirectTo: "/services",
    icon: <FaServer className="h-6 w-6" />,
    active: true,
  },
];

const SideMenuBtns = () => {
  const [reloadState, setReloadState] = useAtom(reload);
  const { windowWidth } = useWindowDimensions();
  const [showMessage, setShowMessage] = useState(false);
  const [isNewQuote, setIsNewQuote] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [quoteDifference, setQuoteDifference] = useState(0);
  const [nonReadNumber, setNonReadNumber] = useState(0); // Nuevo estado para nonReadNumber
  const token = useTokenStore((s) => s.token);

  const getQuotes = async () => {
    try {
      const response = await fetch(`${BASE_URL}${QUOTES}.active`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      const storedQuotes = localStorage.getItem("quotes");
      const currentQuotes = String(data?.result.quotes);

      if (storedQuotes !== currentQuotes) {
        const difference = Math.max(
          0,
          parseInt(currentQuotes) - parseInt(storedQuotes || "0")
        );
        localStorage.setItem("quotes", currentQuotes);
        setNotificationMessage("You have pending quotes");
        setIsNewQuote(true);
        setQuoteDifference(difference);
        setReloadState(true);
      } else {
        setNotificationMessage("No pending Quotes");
        setIsNewQuote(false);
        setQuoteDifference(0);
        setReloadState(false);
      }

      return data?.result;
    } catch (err) {
      console.error(err);
    }
  };

  const getStatus = async () => {
    try {
      const response = await fetch(`${BASE_URL}${QUOTES}.status`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      const nonRead = parseInt(data?.result.newQuotes || "0"); // Asegúrate de convertirlo a número
      setNonReadNumber(nonRead);

      return data?.result;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getQuotes();
    getStatus();
    const intervalId = setInterval(() => {
      getQuotes();
      getStatus(); // Asegúrate de llamar a getStatus también para actualizar nonReadNumber
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (reloadState) {
      intervalId = setInterval(() => {
        document.querySelector(".notification-bell")?.classList.toggle("blink");
      }, 500);
    } else {
      document.querySelector(".notification-bell")?.classList.remove("blink");
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [reloadState]);

  return (
    <SideMenuBtnsStyles>
      <aside className="h-full w-16 flex flex-col tablet:space-y-10 items-center justify-start relative admin-piranha-aside-navbar laptop:pt-10 ">
        {configSide.map((conf, index) => {
          if (windowWidth < 750 && conf.title === "Forms") return null;
          return (
            <NavLink
              key={conf.title}
              to={conf.active ? conf.redirectTo : ""}
              className={({ isActive }) =>
                `admin-piranha-aside-navbar-item h-10 w-10 flex items-center justify-center rounded-lg cursor-pointer admin-piranha-sidenav-icons-hover admin-piranha-bounce-effect ${
                  index === 0 ? "mt-16" : ""
                } ${isActive ? "admin-piranha-menu-active" : ""} ${
                  conf.active ? "opacity-100" : "opacity-40 cursor-not-allowed"
                }`
              }
            >
              <div title={conf.title}>
                {conf.title === "Unread quotes" && nonReadNumber > 0 ? (
                  <div className="relative">
                    {conf.icon}
                    <div className="absolute bottom-3 -right-3 w-5 h-5 bg-red-600 text-white text-xs rounded-full flex items-center justify-center">
                      {nonReadNumber}
                    </div>
                  </div>
                ) : (
                  conf.icon
                )}
              </div>
            </NavLink>
          );
        })}
        <div className="mt-6 relative">
          <div
            className={`admin-piranha-aside-navbar-item h-10 w-10 flex items-center justify-center rounded-lg cursor-pointer admin-piranha-sidenav-icons-hover admin-piranha-bounce-effect notification-bell ${
              isNewQuote ? "blink" : ""
            }`}
            onClick={() => setShowMessage(!showMessage)}
          >
            <FaBell className="h-6 w-6" title="Notifications" />
            {quoteDifference > 0 && (
              <div className="absolute top-0 right-0 w-5 h-5 bg-red-600 text-white text-xs rounded-full flex items-center justify-center">
                {quoteDifference}
              </div>
            )}
          </div>
          {showMessage && (
            <div className="absolute w-48 p-4 bg-white border rounded-lg shadow-lg mt-12">
              <p>{notificationMessage}</p>
            </div>
          )}
        </div>
      </aside>
    </SideMenuBtnsStyles>
  );
};

export default SideMenuBtns;
