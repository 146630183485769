import styled from "styled-components";

import colors from "../../config/colors.config";

export const SideNavBarStyles = styled.div`
  .admin-piranha-navbar {
    background-color: ${colors.primary};
  }

  .admin-piranha-menu-active {
    border-bottom: 0.2em solid ${colors.primary};
  }

  .ap-sidebar {
    background-color: ${colors.quaternary};
  }

  .admin-piranha-sidenav-icons-hover:hover {
    background-color: ${colors.primary};
    color: white;
    transform: scale(1.1);
    transition: 1.5s;
  }

  .admin-piranha-sidenav-icon-active {
    border-bottom: 0.2em solid ${colors.primary};
  }

  .admin-piranha-aside-navbar {
    background-color: white;
  }

  @media (max-width: 750px) {
    .admin-piranha-aside-navbar {
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 4em;
      flex-direction: row;
      justify-content: space-around;
      z-index: 10;

      .admin-piranha-aside-navbar-item {
        margin: auto;
      }
    }
  }

  .blink {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% { color: inherit; }
  50% { color: blue; }
  100% { color: inherit; }
}

`;
