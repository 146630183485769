import { QuoteTypes } from "./types";

export const INITIAL_STATE: QuoteTypes = {
    due_date: "",
    address: "",
    address_line2: "",
    city_town: "",
    country_code: "",
    date_created: "",
    email: "",
    first_name: "",
    id: "",
    last_name: "",
    phone_number: "",
    state_region_province: "",
    date_mail_sent: "",
    zip_code: "",
    score: "",
    fitting_price: null,
    main_part_no: null,
    main_part_price: "",
    registered: "",
    branch: "",
    year: "",
    vehicle: "",
};