import React, { useEffect, useState } from "react";
import { useModalFormDetails } from "../../hooks/use-open-modal-form-details";
import ModalGeneral from "../Modals/ModalGeneral";
import { cn } from "../../lib/utils";
import Quotes from "../../Screens/Quotes";
import { BASE_URL, QUOTES } from "../../config/api.config";
import { useTokenStore } from "../../hooks/useTokenStore";
import { useQuery } from "@tanstack/react-query";

const ModalRowFormDetails = () => {
  const modalFormDetails = useModalFormDetails();
  const [showOption, setShowOption] = useState("details");
  const token = useTokenStore((s) => s.token);

  const getQuoteForms = async () => {
    const data = await fetch(
      `${BASE_URL}${QUOTES}?id=${modalFormDetails.quote?.id}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    console.log(data.result);
    return data.result;
  };

  const { data: quotesDataForm, isLoading: isLoadingQuotesForm } = useQuery({
    queryKey: ["quote_form", modalFormDetails.quote?.id],
    queryFn: getQuoteForms,
    enabled: modalFormDetails.isOpen,
  });

  return (
    <ModalGeneral
      title="Form Details"
      showModal={modalFormDetails.isOpen}
      closeModal={modalFormDetails.onClose}
    >
      <div className="p-4">
        <div className="border-b-2 mb-4 flex items-center  ">
          <h1
            onClick={() => setShowOption("details")}
            className={cn(
              "text-sm    pb-2 px-4 cursor-pointer",
              showOption === "details" && "border-black border-b"
            )}
          >
            Details
          </h1>
          <h1
            onClick={() => setShowOption("address")}
            className={cn(
              "text-sm  pb-2 px-4 cursor-pointer",
              showOption === "address" && "border-black border-b"
            )}
          >
            Address
          </h1>
          <h1
            onClick={() => setShowOption("options")}
            className={cn(
              "text-sm    pb-2 px-4 cursor-pointer",
              showOption === "options" && "border-black border-b"
            )}
          >
            Accesories prices
          </h1>
        </div>

        <div
          className={cn(
            " flex-col items-start w-full hidden transition-all duration-200 ease-in-out",
            showOption === "details" && "block"
          )}
        >
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Score</p>
            <p className="text-sm">${quotesDataForm?.quotes[0].score}</p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Branch</p>
            <p className="text-sm">
              {quotesDataForm?.quotes[0].branch ?? "None"}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Fitting price</p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].fitting_price ?? 0.0}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Main part No</p>
            <p className="text-sm">
              {quotesDataForm?.quotes[0].main_part_no ?? "None"}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Main part price</p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].main_part_price ?? 0.0}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Vehicle</p>
            <p className="text-sm">
              {quotesDataForm?.quotes[0].vehicle ?? "None"}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Vehicle Year</p>
            <p className="text-sm">
              {quotesDataForm?.quotes[0].year ?? "None"}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Premium Fiberglass Canopy</p>
            <p className="text-sm">None</p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Baseline Fiberglass Canopy</p>
            <p className="text-sm">None</p>
          </div>
        </div>
        <div
          className={cn(
            "flex-col items-start w-full hidden transition-all duration-200 ease-in-out",
            showOption === "address" && "block"
          )}
        >
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Address</p>
            <p className="text-sm">{quotesDataForm?.quotes[0].address}</p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Address Line 2</p>
            <p className="text-sm">
              {quotesDataForm?.quotes[0].address_line2 ?? "None"}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">City Town</p>
            <p className="text-sm">{quotesDataForm?.quotes[0].city_town}</p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Country </p>
            <p className="text-sm">
              {quotesDataForm?.quotes[0].country_code ?? "None"}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Province </p>
            <p className="text-sm">
              {quotesDataForm?.quotes[0].state_region_province ?? "None"}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500">Zip Code </p>
            <p className="text-sm">{quotesDataForm?.quotes[0].zip_code}</p>
          </div>
        </div>
        <div
          className={cn(
            " flex-col items-start w-full hidden transition-all duration-200 ease-in-out",
            showOption === "options" && "block"
          )}
        >
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500 capitalize ">
              {quotesDataForm?.quotes[0].acc_part_no1 ?? "Accessory Part 1"}{" "}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no1}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500 capitalize">
              {quotesDataForm?.quotes[0].acc_part_no2 ?? "Accessory Part 2"}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no2}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500 capitalize">
              {quotesDataForm?.quotes[0].acc_part_no3 ?? "Accessory Part 3"}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no3}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500 capitalize">
              {quotesDataForm?.quotes[0].acc_part_no4 ?? "Accessory Part 4"}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no4}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500 capitalize">
              {quotesDataForm?.quotes[0].acc_part_no5 ?? "Accessory Part 5"}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no5}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500 capitalize">
              {quotesDataForm?.quotes[0].acc_part_no6 ?? "Accessory Part 6"}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no6}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1">
            <p className="text-sm text-gray-500 capitalize">
              {quotesDataForm?.quotes[0].acc_part_no7 ?? "Accessory Part 7"}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no7}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1 ">
            <p className="text-sm text-gray-500 capitalize">
              {quotesDataForm?.quotes[0].acc_part_no8 ?? "Accessory Part 8"}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no8}
            </p>
          </div>
          <div className="flex items-center justify-between w-full py-1 ">
            <p className="text-sm text-gray-500 capitalize">
              {quotesDataForm?.quotes[0].acc_part_no9 ?? "Accessory Part 9"}
            </p>
            <p className="text-sm">
              ${quotesDataForm?.quotes[0].acc_price_no9}
            </p>
          </div>
        </div>
      </div>
    </ModalGeneral>
  );
};

export default ModalRowFormDetails;
