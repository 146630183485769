import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ShowModal } from "./ManagementGeneral";
import { OptionsTypes } from "../../types";
import { Input } from "../ui/Input";
import { Button, buttonVariants } from "../ui/Button";
import { cn } from "../../lib/utils";
import { useAtom } from "jotai";
import { editFunctionLoading } from "../../atoms";

interface ModalManagementProps {
  section: string;
  type: string;
  showModal: ShowModal;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  handleAction: (args?: any, args2?: any, args3?: any, args4?: any) => void;
  selectedVehicle: OptionsTypes;
}

const ModalManagement: React.FC<ModalManagementProps> = ({
  handleAction,
  section,
  selectedVehicle,
  setShowModal,
  showModal,
  type,
}) => {
  const [newName, setNewName] = useState("");
  const [vehicleInput, setVehicleInput] = useState("");
  const [isLoading, setIsLoading] = useAtom(editFunctionLoading);

  const handleContinue = () => {
    if (section === "vehicle") {
      if (type === "vehicle") {
        handleAction(selectedVehicle.id);
      } else {
        handleAction(selectedVehicle.id, newName);
      }
    }
  };

  useEffect(() => {
    if (section === "vehicle") {
      setNewName(selectedVehicle.option_text);
    } else if (section === "year") {
      setNewName(selectedVehicle.option_text);
    }
  }, [section, selectedVehicle]);

  return (
    <div>
      <div
        className={cn(
          "fixed top-0 left-0 w-full h-full  bg-black/50 !text-black z-50"
        )}
      >
        <div
          className={cn(
            "fixed z-50 w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white",
            showModal ? "animate-slide-in" : "animate-slide-out"
          )}
        >
          <div className="text-xl font-bold border-b px-8 py-6">
            {section === "vehicle" && (
              <>{type === "delete" ? "Delete vehicle" : "Edit vehicle"}</>
            )}
            {section === "year" && (
              <>{type === "delete" ? "Delete year" : "Edit year"}</>
            )}
            {section === "branch" && (
              <>{type === "delete" ? "Delete branch" : "Edit branch"}</>
            )}
            {section === "quoted-options" && (
              <>{type === "delete" ? "Delete Option" : "Edit Option"}</>
            )}
          </div>
          <div className="mx-8 my-6">
            {type === "delete" ? (
              <>
                {section === "vehicle" && (
                  <p>
                    Are you sure you want to delete the vehicle{" "}
                    <span className="font-bold">
                      '{selectedVehicle?.option_text}'
                    </span>{" "}
                  </p>
                )}
                {section === "year" && (
                  <p>
                    Are you sure you want to delete the year{" "}
                    <span className="font-bold">
                      '{selectedVehicle?.option_text}'
                    </span>{" "}
                  </p>
                )}
                {section === "branch" && (
                  <p>
                    Are you sure you want to delete the branch{" "}
                    <span className="font-bold">
                      '{selectedVehicle?.option_text}'
                    </span>{" "}
                  </p>
                )}
                {section === "quoted-options" && (
                  <p>
                    Are you sure you want to delete the quoted option{" "}
                    <span className="font-bold">
                      '{selectedVehicle?.option_text}'
                    </span>{" "}
                  </p>
                )}
              </>
            ) : (
              <>
                {section === "vehicle" && (
                  <div className="flex flex-col gap-4">
                    <p>Change Vehicle Name</p>
                    <Input
                      defaultValue={selectedVehicle?.option_text}
                      className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                      onChange={(e) => setNewName(e.target.value)}
                    />
                  </div>
                )}
                {section === "year" && (
                  <div className="flex flex-col gap-4">
                    <p>Change Year</p>
                    <Input
                      defaultValue={selectedVehicle?.option_text}
                      className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                      onChange={(e) => setNewName(e.target.value)}
                    />
                  </div>
                )}
                {section === "branch" && (
                  <div className="flex flex-col gap-4">
                    <p>Change Branch</p>
                    <Input
                      defaultValue={selectedVehicle?.option_text}
                      className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                      onChange={(e) => setNewName(e.target.value)}
                    />
                  </div>
                )}
                {section === "quoted-options" && (
                  <div className="flex flex-col gap-4">
                    <p>Change Quoted Options</p>
                    <Input
                      defaultValue={selectedVehicle?.option_text}
                      className="outline-none border border-blue-100 h-10 text-sm rounded hover:border-[#2abeeb] focus:border-[#2abeeb] px-4"
                      onChange={(e) => setNewName(e.target.value)}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end">
            <Button
              className={cn(buttonVariants({ variant: "ghost" }))}
              onClick={() =>
                setShowModal((prev: any) => ({
                  ...prev,
                  edit: false,
                  delete: false,
                }))
              }
            >
              Cancel
            </Button>
            {section === "vehicle" && type !== "delete" && (
              <Button
                isLoading={isLoading}
                disabled={newName === ""}
                onClick={() => handleContinue()}
              >
                Save
              </Button>
            )}
            {section === "vehicle" && type === "delete" && (
              <Button isLoading={isLoading} onClick={handleContinue}>
                Delete
              </Button>
            )}
            {section === "year" && type !== "delete" && (
              <Button
                isLoading={isLoading}
                disabled={newName === ""}
                onClick={() => handleContinue()}
              >
                Save
              </Button>
            )}
            {section === "year" && type === "delete" && (
              <Button isLoading={isLoading} onClick={handleContinue}>
                Delete
              </Button>
            )}
            {section === "branch" && type !== "delete" && (
              <Button
                isLoading={isLoading}
                disabled={newName === ""}
                onClick={() => handleContinue()}
              >
                Save
              </Button>
            )}
            {section === "branch" && type === "delete" && (
              <Button isLoading={isLoading} onClick={handleContinue}>
                Delete
              </Button>
            )}
            {section === "quoted-options" && type !== "delete" && (
              <Button
                isLoading={isLoading}
                disabled={newName === ""}
                onClick={() => handleContinue()}
              >
                Save
              </Button>
            )}
            {section === "quoted-options" && type === "delete" && (
              <Button isLoading={isLoading} onClick={handleContinue}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalManagement;
