import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { cn } from "../../lib/utils";
import { FaMountain, FaRegUser } from "react-icons/fa";
import { MdAbc, MdFormatQuote } from "react-icons/md";
import { BsChevronDown, BsThreeDotsVertical } from "react-icons/bs";
import { BiDuplicate, BiTrash } from "react-icons/bi";
import { VscLayers } from "react-icons/vsc";
import { MdLocationOn } from "react-icons/md";

export type Form = {
  id: string;
  type: string;
  question: string;
  number_form: number;
};

interface MetricsFormsCardProps {
  form: Form;
  setSelectForm: Dispatch<SetStateAction<Form>>;
  selectForm: Form;
}

const MetricsFormsCard = ({
  form,
  selectForm,
  setSelectForm,
}: MetricsFormsCardProps) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ref = useRef<any>();

  useEffect(() => {
    // Function to handle the click event
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      key={form.id}
      onClick={() => setSelectForm(form)}
      className={cn(
        "py-2 flex items-start space-x-2 relative p-2 w-60  my-1 cursor-pointer hover:bg-gray-200 rounded-lg transition mx-1 group",
        form.number_form === selectForm?.number_form && "bg-gray-200"
      )}
    >
      <div
        className={cn(
          "w-[45px] h-7 flex justify-between border items-center space-x-2 px-1 rounded-lg",
          form.type === "contact_information" && "bg-green-400",
          form.type === "multiple_selection" && "bg-blue-300",
          form.type === "selector" && "bg-slate-300",
          form.type === "selector_with_images" && "bg-gray-300",
          form.type === "message" && "bg-[#e2e2e2]",
          form.type === "address" && "bg-green-400"
        )}
      >
        {form.type === "contact_information" && (
          <div className="w-6">
            <FaRegUser className="h-4 w-4" />
          </div>
        )}
        {form.type === "multiple_selection" && (
          <div className="w-6">
            <MdAbc className="h-4 w-4" />
          </div>
        )}
        {form.type === "selector" && (
          <div className="w-6">
            <BsChevronDown className="h-4 w-4" />
          </div>
        )}
        {form.type === "selector_with_images" && (
          <div className="w-6">
            <FaMountain className="h-4 w-4 " />
          </div>
        )}
        {form.type === "entry" && (
          <div className="w-6">
            <VscLayers className="h-4 w-4 -rotate-90" />
          </div>
        )}
        {form.type === "address" && (
          <div className="w-6">
            <MdLocationOn className="h-4 w-4" />
          </div>
        )}
        {form.type === "message" && (
          <div className="w-6">
            <MdFormatQuote className="h-4 w-4" />
          </div>
        )}
        <p className="text-[12px] font-semibold">{form.number_form}</p>
      </div>
      <h3 className="text-[13px] text-gray-500 w-36 select-none  font-normal text-ellipsis line-clamp-1">
        {form.question}
      </h3>
      <div
        className={cn(
          "hidden group-hover:inline-flex mt-1 hover:bg-gray-300 h-6 w-6 transition rounded-lg items-center justify-center ",
          showDropDown &&
            "inline-flex h-6 w-6 transition rounded-lg items-center justify-center"
        )}
      >
        <BsThreeDotsVertical
          onClick={() => setShowDropDown(true)}
          className="h-4 w-4 justify-center"
        />
      </div>

      <div
        ref={ref}
        className={cn(
          "h-20 w-32   bg-white absolute -top-3 transition rounded-xl -right-32 z-20 shadow border-4 border-gray-200 ",
          showDropDown ? "block" : "hidden"
        )}
      >
        <div className="flex items-center space-x-2 px-3 py-2">
          <BiDuplicate className="h-5 w-5" />
          <p className="text-sm">Duplicate</p>
        </div>
        <div className="flex items-center space-x-2 px-3 py-2">
          <BiTrash className="h-5 w-5" />
          <p className="text-sm">Remove</p>
        </div>
      </div>
    </div>
  );
};

export default MetricsFormsCard;
